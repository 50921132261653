import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state

export interface ICustomerRegister {
  userName: string;
  password: string;
  name: string;
  phone: string;
  gender: string;
  birthDay: string;
  email: string;
  address: string;
  job: string;
  nameBank: string;
  codeBank: string;
  created_by: string;
}

interface RegisterFormState {
  messengerUserName: string;
  messengerPhone: string;
}

// Define the initial state using that type
const initialState: RegisterFormState = {
  messengerUserName: '',
  messengerPhone: '',
};

export const registerFormSlice = createSlice({
  name: 'registerform',
  initialState,
  reducers: {
    checkUserName: (state, action) => {
      state.messengerUserName = action.payload;
    },

    checkPhone: (state, action) => {
      state.messengerPhone = action.payload;
    },
  },
});

export const { checkUserName, checkPhone } = registerFormSlice.actions;

export default registerFormSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/atoms/button';
import { Icon } from 'src/components/atoms/icon';
import { mapModifiers } from 'src/libs/component';

interface NotificationProps {
  children: React.ReactNode;
  hasNotification?: boolean;
}

export const Notification: React.FC<NotificationProps> = props => {
  const [isShow, setIsShow] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const notificationRef = ref.current;

    const hanleClick = (event: MouseEvent) => {
      const notificationOpener = document.querySelector('.m-notification .a-button');
      const iconNotification = document.querySelector('.m-notification .a-icon');
      const notificationItems = document.querySelectorAll('.m-notification__item');
      let isInside = false;

      if (event.target) {
        notificationItems.forEach(item => {
          if (item === event.target) isInside = true;
        });

        if (
          event.target !== iconNotification &&
          event.target !== notificationOpener &&
          event.target !== notificationRef &&
          !isInside
        )
          setIsShow(false);
        else if (event.target === iconNotification || event.target === notificationOpener) setIsShow(true);
      }
    };

    document.addEventListener('click', hanleClick);

    return () => document.removeEventListener('click', hanleClick);
  }, []);

  return (
    <div className={mapModifiers('m-notification', isShow && 'active')}>
      <Button modifiers="only-icon" onClick={() => setIsShow(true)}>
        <Icon name="notification" />
        {props.hasNotification && <span className="m-notification__red-dot" />}
      </Button>
      {props.hasNotification && (
        <ul className="m-notification__items" ref={ref}>
          {props.children}
        </ul>
      )}
    </div>
  );
};

interface NotificationItemProps {
  children: React.ReactNode;
}

export const NotificationItem: React.FC<NotificationItemProps> = props => (
  <li className="m-notification__item">{props.children}</li>
);

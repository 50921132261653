import React from 'react';
import { mapModifiers } from 'src/libs/component';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  children?: React.ReactNode;
  modifiers?:
    | 'delete'
    | 'disabled'
    | 'edit'
    | 'icon-add'
    | 'icon-clear'
    | 'link-delete'
    | 'link-disable'
    | 'link-update'
    | 'link'
    | 'login'
    | 'logout'
    | 'more'
    | 'only-icon'
    | 'analysis-test-results'
    | 'register';
  ref?: React.Ref<HTMLButtonElement>;
}

export const Button: React.FC<ButtonProps> = React.forwardRef(
  ({ children, modifiers, ...buttonProps }, ref) => (
    <button {...buttonProps} className={mapModifiers('a-button', modifiers)} ref={ref}>
      {children}
    </button>
  )
);

Button.displayName = 'Button';

/* eslint-disable react/jsx-key */
import React from 'react';
import { Image } from 'src/components/atoms/image';
import { TextLink } from 'src/components/atoms/text-link';

export interface LinkAppMobileProps {
  src: string;
  alt?: string;
  href: string;
  useExternalLink?: boolean;
}

export const LinkAppMobile: React.FC<LinkAppMobileProps> = ({ src, alt, href, useExternalLink }) => (
  <TextLink href={href} useExternalLink={useExternalLink}>
    <Image url={src} alt={alt} />
  </TextLink>
);

import { createSlice } from '@reduxjs/toolkit';
import { WorkTimeObject, WorkUnitRequest } from 'src/api/models/work-unit';
import { removeFromLocalStorage } from 'src/libs/utils';

export interface IHealthCareBookingSuccess {
  dateTest: string;
  dateTestExpired: string;
  userId: number;
  fullName: string;
  orderId: number;
  diagnostic: string;
  reasonForExamination: string;
  note?: string;
  timeCustome: boolean;
  bookingUser: {
    doctor?: boolean;
    fullName: string;
    phone: string;
    userId: number;
  };
  orderType: string;
  department: {
    id: number;
    name: string;
  };
  doctor: {
    id: number;
    name: string;
  };
  infomation: {
    id: number;
    dateOfBirthday: string;
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    address: string;
  };
}

export interface IInfoUserBookingHealthCare {
  id?: number;
  fullName: string;
  phone: string;
  dateOfBirth: string;
  gender: string;
  address: string;
  email?: string;
  infoId: number;
}

export interface IInfoBookingHealthCareOject {
  isFamily?: boolean;
  userInfo?: IInfoUserBookingHealthCare;
  bookingInfo: {
    specialist: number;
    doctor: number;
    isOptional?: boolean;
    date: string;
    hour: string;
    note?: string;
    diagnostic: string;
    reasonForExamination: string;
  };
  previousPhoneNumber?: string;
}

export interface IInfoBookingHealthCare {
  isFamily?: boolean;
  inforBookingForSelf: IInfoBookingHealthCareOject[];
  inforBookingForOther: IInfoBookingHealthCareOject[];
}

export interface WorkTimeInMonthWithDoctorID {
  doctorId: number;
  month: number;
  year: number;
  worktime: WorkTimeObject[];
}

export interface HealthCareState {
  infoBookings?: IInfoBookingHealthCare;
  healthServiceDivision: WorkUnitRequest[];
  workTimeList: WorkTimeInMonthWithDoctorID[];
  orderSuccessList: IHealthCareBookingSuccess[];
}

const initialState: HealthCareState = {
  infoBookings: {
    inforBookingForOther: [],
    inforBookingForSelf: [],
  },
  healthServiceDivision: [],
  workTimeList: [],
  orderSuccessList: [],
};

export const healthCareSlice = createSlice({
  name: 'healthcare',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReset: state => {
      state.healthServiceDivision = initialState.healthServiceDivision;
      state.infoBookings = initialState.infoBookings;
      state.orderSuccessList = initialState.orderSuccessList;
      state.workTimeList = initialState.workTimeList;
    },

    setInfoBookings: (state, action) => {
      state.infoBookings = action.payload;
    },
    setHealthServiceDivision: (state, action) => {
      state.healthServiceDivision = action.payload;
    },
    setWorkTimeList: (state, action) => {
      state.workTimeList = action.payload;
    },
    setOrderSuccessIdList: (state, action) => {
      state.orderSuccessList = action.payload;
    },
    clear: () => {
      removeFromLocalStorage('persist:healthcare', true);
    },
  },
});

export const {
  setInfoBookings,
  setHealthServiceDivision,
  setWorkTimeList,
  setOrderSuccessIdList,
  clear,
  setReset,
} = healthCareSlice.actions;

export default healthCareSlice.reducer;

import React from 'react';
import { mapModifiers } from 'src/libs/component';
import Modal from 'react-modal';
import { Icon } from 'src/components/atoms/icon';

export interface ModalBoxProps {
  children: React.ReactNode;
  isOpen?: boolean;
  modifier?: 'large' | 'full-screen';
  onlyButtonConfirm?: boolean;
  title?: string;
  handleCloseRequest?: () => void;
  footer?: React.ReactNode;
  responsive?: boolean;
  marginTop?: boolean;
}

export const ModalBox: React.FC<ModalBoxProps> = props => (
  <Modal
    appElement={document.querySelector('#root') as HTMLElement}
    isOpen={!!props.isOpen}
    overlayClassName="o-modal-box__overlay"
    className={mapModifiers('o-modal-box__content', props.responsive && 'responsive', props.modifier)}
    portalClassName={mapModifiers('o-modal-box')}
  >
    <header className="o-modal-box__header">
      <div className="o-modal-box__title">{props.title}</div>
      <div className="o-modal-box__button-container">
        <button className="o-modal-box__button" onClick={props.handleCloseRequest}>
          <Icon name="close" />
        </button>
      </div>
    </header>
    <main className="o-modal-box__main" style={{ marginTop: props.marginTop ? '0' : '' }}>
      {props.children}
    </main>
    {props.footer && <footer className="o-modal-box__footer">{props.footer}</footer>}
  </Modal>
);

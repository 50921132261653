import React from 'react';
import { mapModifiers } from 'src/libs/component';

interface LoadingProps {
  open?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ open }) => (
  <div className={mapModifiers('a-loading', open && 'opened')} />
);

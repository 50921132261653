import React from 'react';
import { mapModifiers } from 'src/libs/component';

export interface ButtonContainerProps {
  children: React.ReactNode | React.ReactNode[];
  align?: 'right' | 'left';
  noMargin?: boolean;
  noMarginTop?: boolean;
  noSetWidth?: boolean;
  marginLeft?: boolean;
}

export const ButtonContainer: React.FC<ButtonContainerProps> = ({
  children,
  noMargin,
  align,
  noSetWidth,
  marginLeft,
  noMarginTop,
}) => (
  <ul
    className={mapModifiers(
      'm-button-container',
      align,
      noSetWidth && 'no-set-width',
      marginLeft && 'margin-left',
      noMargin && 'no-margin',
      noMarginTop && 'no-margin-top'
    )}
  >
    {React.Children.map(children, (button, index) => (
      <li className="m-button-container__item" key={index}>
        {button}
      </li>
    ))}
  </ul>
);

import React, { lazy, Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';

import { Loading } from 'src/components/atoms/loading';

import PrivateRoute from 'src/components/templates/protected-route';

import PATHES from 'src/constants/pathes';
import DetailHealthRecord from './pages/detail-health-record';

const HomePage = lazy(() => import('src/pages/home-page'));
const LoginPage = lazy(() => import('src/pages/login'));
const ForgetPasswordPage = lazy(() => import('src/pages/forget-password-page'));
const DetailApplication = lazy(() => import('src/pages/detail-application-list'));
const DetailDiagnosticTestEdit = lazy(() => import('src/pages/detail-diagnostic-test-edit'));
const DetailDiagnosticTestEditDoctor = lazy(() => import('src/pages/detail-diagnostic-test-edit-doctor'));
const DetailImageAnalysationEdit = lazy(() => import('src/pages/detail-image-analysation-edit'));
const DetailImageAnalysationEditDoctor = lazy(() => import('src/pages/detail-image-analysation-edit-doctor'));
const DetailInfoUserEdit = lazy(() => import('src/pages/detail-info-user-edit'));
const DetailHealthCareEdit = lazy(() => import('src/pages/detail-health-care-edit'));
const HealthCareEditDoctor = lazy(() => import('src/pages/detail-health-care-edit-doctor'));
const DetailScheduleManagement = lazy(() => import('src/pages/detail-schedule-management'));
const DetailDiagnosticTest = lazy(() => import('src/pages/detail-diagnostic-test'));
const DetailDiagnosticTestConfirm = lazy(() => import('src/pages/detail-diagnostic-test/confirm'));
const DetailDiagnosticTestComplete = lazy(() => import('src/pages/detail-diagnostic-test/complete'));
const DetailHealthCare = lazy(() => import('src/pages/detail-health-care'));
const DetailHealthCareConfirm = lazy(() => import('src/pages/detail-health-care/confirm'));
const DetailHealthCareComplete = lazy(() => import('src/pages/detail-health-care/complete'));
const DetailImageAnalysation = lazy(() => import('src/pages/detail-image-analysation'));
const DetailImageAnalysationConfirm = lazy(() => import('src/pages/detail-image-analysation/confirm'));
const DetailImageAnalysationComplete = lazy(() => import('src/pages/detail-image-analysation/complete'));
const DetailBookingSuccess = lazy(() => import('src/pages/detail-booking-success'));
const OrderListInMonth = lazy(() => import('src/pages/order-list-in-month'));
const HealthMonitoring = lazy(() => import('src/pages/detail-health-monitoring'));
const AllOrders = lazy(() => import('src/pages/all-orders'));
const DetailRegister = lazy(() => import('src/pages/detail-register'));
const Error404 = lazy(() => import('src/pages/error-404'));
const Error500 = lazy(() => import('src/pages/error-500'));
const RelativeManagement = lazy(() => import('src/pages/detail-relative-management'));
const PatientInformation = lazy(() => import('src/pages/patient-information'));
const MedicalInformation = lazy(() => import('src/pages/medical-information'));

export const App: React.FC = () => (
  <Suspense fallback={<Loading open />}>
    <Routes>
      <Route path={PATHES['HOME_PAGE']} element={<HomePage />} />
      <Route path={PATHES['LOGIN_PAGE']} element={<LoginPage />} />
      <Route path={PATHES['FORGET_PASSWORD_PAGE']} element={<ForgetPasswordPage />} />
      <Route path={PATHES['REGISTER_PAGE']} element={<DetailRegister />} />
      <Route path={`${PATHES['DETAIL_BOOKING_SUCCESS_PAGE']}:id`} element={<DetailBookingSuccess />} />
      <Route element={<PrivateRoute />}>
        <Route path={PATHES['HEALTH_CARE_PAGE']} element={<DetailHealthCare />} />
        <Route path={PATHES['HEALTH_CARE_CONFIRM_PAGE']} element={<DetailHealthCareConfirm />} />
        <Route path={PATHES['HEALTH_CARE_COMPLETE_PAGE']} element={<DetailHealthCareComplete />} />
        <Route path={`${PATHES['HEALTH_CARE_EDIT_PAGE']}:id`} element={<DetailHealthCareEdit />} />
        <Route path={`${PATHES['HEALTH_CARE_DOCTOR_EDIT_PAGE']}`}>
          <Route path={':id'} element={<HealthCareEditDoctor />} />
          <Route path={'new/:patientId/:doctorId'} element={<HealthCareEditDoctor />} />
        </Route>
        <Route path={PATHES['INFO_USER_PAGE']} element={<DetailInfoUserEdit />} />
        <Route path={PATHES['DIAGNOSTIC_TEST_PAGE']} element={<DetailDiagnosticTest />} />
        <Route path={PATHES['DIAGNOSTIC_TEST_CONFIRM_PAGE']} element={<DetailDiagnosticTestConfirm />} />
        <Route path={PATHES['DIAGNOSTIC_TEST_COMPLETE_PAGE']} element={<DetailDiagnosticTestComplete />} />
        <Route path={`${PATHES['DIAGNOSTIC_TEST_EDIT_PAGE']}:id`} element={<DetailDiagnosticTestEdit />} />
        <Route
          path={`${PATHES['DIAGNOSTIC_TEST_DOCTOR_EDIT_PAGE']}:id`}
          element={<DetailDiagnosticTestEditDoctor />}
        />
        <Route
          path={`${PATHES['IMAGE_ANALYSATION_DOCTOR_EDIT_PAGE']}:id`}
          element={<DetailImageAnalysationEditDoctor />}
        />
        <Route path={PATHES['IMAGE_ANALYSATION_PAGE']} element={<DetailImageAnalysation />} />

        <Route path={PATHES['HEALTH_RECORD_PAGE']} element={<DetailHealthRecord />} />
        <Route
          path={`${PATHES['HEALTH_RECORD_PAGE']}:id`}
          element={<DetailHealthRecord />}
        />

        <Route path={PATHES['IMAGE_ANALYSATION_CONFIRM_PAGE']} element={<DetailImageAnalysationConfirm />} />
        <Route
          path={PATHES['IMAGE_ANALYSATION_COMPLETE_PAGE']}
          element={<DetailImageAnalysationComplete />}
        />
        <Route
          path={`${PATHES['IMAGE_ANALYSATION_EDIT_PAGE']}:id`}
          element={<DetailImageAnalysationEdit />}
        />
        <Route path={PATHES['MEDICAL_INFORMATION_PAGE']} element={<MedicalInformation />} />
        <Route path={`${PATHES['DETAIL_BOOKING_SUCCESS_PAGE']}`} element={<DetailBookingSuccess />} />
        <Route path={`${PATHES['DETAIL_APPLICATION_LIST_PAGE']}`} element={<DetailApplication />} />
        <Route path={`${PATHES['DETAIL_SCHEDULE_MANAGEMENT_PAGE']}`} element={<DetailScheduleManagement />} />
        <Route path={PATHES['ORDER_LIST_IN_MONTH_PAGE']} element={<OrderListInMonth />} />
        <Route path={`${PATHES['ALL_ORDERS_PAGE']}:id`} element={<AllOrders />} />
        <Route path={PATHES['HEALTH_MONITORING_PAGE']} element={<HealthMonitoring />} />
        <Route path={PATHES['RELATIVE_MANAGEMENT_PAGE']} element={<RelativeManagement />} />
        <Route path={PATHES['PATIENT_INFORMATION_PAGE']} element={<PatientInformation />} />
      </Route>
      <Route path={PATHES['ERROR_500_PAGE']} element={<Error500 />} />
      <Route path="*" element={<Error404 />} />
      <Route path="test" element={<>test</>} />
    </Routes>
  </Suspense>
);

export default App;

import React from 'react';
import { mapModifiers } from 'src/libs/component';
import { TextLink } from '../text-link';

export const BreadScrum: React.FC = ({ children }) => <ul className="a-breadscrum">{children}</ul>;

interface BreadScrumItemProps {
  href?: string;
  children: string;
  onClick?: () => void;
}

export const BreadScrumItem: React.FC<BreadScrumItemProps> = ({ href, children, onClick }) => (
  <li className={mapModifiers('a-breadscrum__item', onClick && 'with-textlink')} onClick={onClick}>
    {href ? (
      <TextLink href={href} modifiers="with-textLink">
        {children}
      </TextLink>
    ) : (
      children
    )}
  </li>
);

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Option, Select } from '../select';
import iconClear from 'src/assets/icons/clear.png';
import docImg from 'src/assets/icons/file/doc.png';
import fileImg from 'src/assets/icons/file/file.png';
import pdfImg from 'src/assets/icons/file/pdf.png';
import rarImg from 'src/assets/icons/file/rar.png';
import xlsImg from 'src/assets/icons/file/xls.png';
import zipImg from 'src/assets/icons/file/zip.png';
import { BASE_URL } from 'src/libs/http-interceptor';
import { useTranslation } from 'react-i18next';
import { Input } from '../input';
import { Button } from '../button';
export interface UploadProps {
  name: string;
  readonly?: boolean;
  data?: FileDetailProps[];
  maxSize: number;
  typeExam?: number[];
  clearFileFromServer?: (data: string) => void;
  onChange?: (data: FileDetailProps[]) => void;
  disable: boolean;
}
export interface FileDetailProps {
  id?: string;
  fileId?: string;
  name: string;
  img: string;
  size: string;
  fileName?: string;
  type?: string;
  dowloadUri?: string;
  file: File | null;
  typeExam: number | 0;
  displayName: string;

}
export const Upload: React.FC<UploadProps> = ({ typeExam, name, readonly, maxSize, data, clearFileFromServer, onChange, disable }) => {
  const { t } = useTranslation();
  const arrOption = typeExam || [1, 2, 3];
  const defaultFile: FileDetailProps = {
    name: '',
    img: '',
    size: '',
    file: null,
    typeExam: typeExam ? typeExam[0] : 1,
    displayName: ''
  };
  const [detailFile, setDetailFile] = useState<FileDetailProps[]>([
  ]);
  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  }
  const handleFile = (file: File, idx: number) => {
    const detail = detailFile;

    detail[idx].name = file.name;
    detail[idx].file = file;
    detail[idx].size = formatFileSize(file.size);

    if (file.type.includes('word')) {
      detail[idx].img = docImg;
      return detail;
    }
    if (file.type.includes('pdf')) {
      detail[idx].img = pdfImg;
      return detail;
    }

    if (file.type.includes('rar')) {
      detail[idx].img = rarImg;
      return detail;
    }

    if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
      detail[idx].img = xlsImg;
      return detail;
    }

    if (file.type.includes('zip')) {
      detail[idx].img = zipImg;
      return detail;
    }

    detail[idx].img = fileImg;
    return detail;
  };

  const handleFileString = (file: string, name: string) => {
    if (file.includes('word') || ['doc', 'docx', 'odt', 'rtf'].includes(name.toLowerCase().split('.')[1])) {
      return docImg;
    }
    if (file.includes('pdf') || ['pdf'].includes(name.toLowerCase().split('.')[1])) {
      return pdfImg;
    }

    if (file.includes('rar') || ['rar'].includes(name.toLowerCase().split('.')[1])) {
      return rarImg;
    }

    if (name.includes('.xls') || name.includes('.xlsx')) {
      return xlsImg;
    }

    if (
      file.includes('zip') ||
      ['zip'].includes(name.toLowerCase().split('.')[1]) ||
      ['7zip'].includes(name.toLowerCase().split('.')[1])
    ) {
      return zipImg;
    }

    return fileImg;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, idx: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (
          file.type.includes('image') ||
          ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.tif', '.tiff', '.bmp', '.webp'].includes(
            file.name.toLowerCase()
          )
        ) {
          const detail = detailFile;
          detail[idx].img = e.target?.result as string;
          detail[idx].name = file.name;
          detail[idx].size = formatFileSize(file.size);
          detail[idx].file = file;
          detail[idx].typeExam = detail[idx].type ? Number(detail[idx].type) : (typeExam ? typeExam[0] : 1);

          setDetailFile([...detail]);
        } else {
          const detail = handleFile(file, idx);

          setDetailFile([...detail]);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  function handleSelectType(e: ChangeEvent<HTMLSelectElement>, idxItem: number) {
    const newFileDetails: FileDetailProps[] = [...detailFile];
    newFileDetails[idxItem].typeExam = Number(e.target.value);
    setDetailFile([...newFileDetails]);
  }
  const handleClear = (idx: number, fileId?: string) => {
    //console.log('handle clear idx: ', idx, ' field:', fileId);
    if (fileId) {
      typeof clearFileFromServer === 'function' && clearFileFromServer && clearFileFromServer(fileId);
    } else {
      const arr = [...detailFile];
      delete arr[idx]
      setDetailFile(arr.filter(e => e));
    }
  };

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>, idxItem: number) => {
    const newFileDetails: FileDetailProps[] = [...detailFile];
    newFileDetails[idxItem].displayName = String(e.target.value);
    setDetailFile([...newFileDetails]);
  };

  useEffect(() => {
    if (
      data
    ) {
      const dataFinal: FileDetailProps[] = [];
      data.map((item) => {
        const name = item.fileName ? item.fileName.toLowerCase().split('.')[1] : '';
        const checkImg = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'tif', 'tiff', 'bmp', 'webp'].includes(
          item.name ? item.name.toLowerCase().split('.')[1] : name
        );
        dataFinal.push({
          ...item,
          name: item.name || item.fileName || '',
          img: checkImg
            ? BASE_URL + item.dowloadUri
            : handleFileString(item.type || '', item.name || item.fileName || ''),
        });
      });
      setDetailFile(dataFinal);
    }
    if (!data) {
      setDetailFile([]);
    }
  }, [data]);
  useEffect(() => {
    onChange && onChange(detailFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailFile]);
  return (
    <>
      {detailFile.map((item, idx) => (
        <div key={idx} className="upload-custom">
          {item.name !== '' &&
            (item.id ? (
              <a
                href={`${item.dowloadUri ? BASE_URL + item.dowloadUri : undefined
                  } `}
                target="blank"
                style={{ textDecoration: 'unset' }}
              >
                <nav className="upload-custom__box">
                  <img className="upload-custom__box__img" src={item.img} />
                  <nav className="upload-custom__box__des">
                    <p style={{ textDecoration: 'underline', textOverflow: 'ellipsis', maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.name}</p>
                    <p style={{ textDecoration: 'unset' }}>{item.size}</p>
                  </nav>
                </nav>
              </a>
            ) : (
              <nav className="upload-custom__box">
                <img className="upload-custom__box__img" src={item.img} />
                <nav className="upload-custom__box__des">
                  <p style={{ textDecoration: 'underline', textOverflow: 'ellipsis', maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.name}</p>
                  <p style={{ textDecoration: 'unset' }}>{item.size}</p>
                </nav>
              </nav>
            ))}
          {item.name === '' && (
            <>
              <input
                id={typeExam ? typeExam[0] + "" : "" + name + item}
                className="upload-custom__input"
                type="file"
                onChange={e => handleFileChange(e, idx)}
                readOnly={readonly}
              />
              <label htmlFor={typeExam ? typeExam[0] + "" : "" + name + item}>
                <p className="upload-custom__btn">{t('common.upload_file')}</p>
              </label>
            </>
          )}
          <Input value={item.displayName} horizontal noMarginTop label={t('commen.display_name') || ''} placeholder={t('common.display_name-placeholder') || ''}
            onChange={e => handleDisplayNameChange(e, idx)

            }
          />
          <Select
            noWrap
            defaultValue={1}
            disabled={readonly}
            title={t('common.type')}
            className="upload-custom__select"
            value={item.typeExam}
            onChange={e => handleSelectType(e, idx)}
          >
            {arrOption.map((value, idx) => {
              //if (type[item - 1] === value || !type.includes(value)) {
              return (
                <Option key={idx} value={value}>
                  {
                    value === 1 ? t('home.test')
                      : value === 2 ? t('home.image')
                        : value === 3 ? t('info.other')
                          : t('home.prescription')}
                </Option>
              );
              //}
            })}
          </Select>
          {!readonly && (
            <nav
              className="upload-custom__clear"
              style={disable ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
              onClick={() => !disable && handleClear(idx, item.fileId)}
            >
              <p style={{ marginRight: '5px' }}>{t('common.delete-file')}</p>
              <img src={iconClear} style={{ width: '20px', height: '20px' }} />
            </nav>
          )}
        </div >
      ))}
      {!readonly && detailFile.length < maxSize && <Button disabled={disable} type="button" modifiers="login" style={{ width: '100%', marginTop: '10px', padding: '10px 0' }} onClick={() => setDetailFile([...detailFile, { ...defaultFile }])}>{arrOption.length === 1 ? t('record.add-prescription') : t('record.add-document')}</Button>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        <p style={{ textAlign: 'end', marginTop: '10px', fontSize: '15px', marginRight: '20px' }} >{`${t('common.total_files')}: ` + detailFile.filter(item => item.name !== '').length}</p>
        {/* <p style={{ textAlign: 'end', marginTop: '10px', fontSize: '15px' }}>{`${t('common.total_import')}: ` + detailFile.length}</p> */}
      </div>
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button } from 'src/components/atoms/button';
import { DateInput } from 'src/components/atoms/date-input';
import { Heading } from 'src/components/atoms/heading';
import { Input } from 'src/components/atoms/input';
import { Textarea } from 'src/components/atoms/textarea';
import { FormHeading } from 'src/components/atoms/form-heading';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import {
  setHealthServiceDivision,
} from 'src/redux/features/health-care-slice';
import PATHES from 'src/constants/pathes';

import { ButtonContainer } from 'src/components/molecules/button-container';
import { useGetUserRelative } from 'src/api/user';
import { useGetWorkUnit, } from 'src/api/workUnit';
import { Loading } from 'src/components/atoms/loading';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18n from 'src/utils/translate/config';
import { FileDetailProps, Upload } from 'src/components/atoms/upload-record-files';
import { getOrderDetailId, postHealthRecord, useDeleteOrderId, usePutOrderId, putHealthRecord, useDeleteDocument, } from 'src/api/order';
import { OrderDetailIdRequest, RecordDataType } from 'src/api/models/order';
import { NotifyToast, Toaster } from 'src/components/atoms/action-toast';
import { Order } from 'src/components/atoms/order';
import { ConfirmAlert } from '../confirm-alert';

interface InfoFormHealthRecord {
  date: string;
  doctor: string;
  reasonForExamination: string;
}

export const HealthRecordForm: React.FC = () => {
  const location = useLocation();
  const canEdit = (location?.state as { edit?: boolean })?.edit;
  const { id } = useParams();
  const [language, setLanguage] = useState(i18n.language);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(state => state.authSlice);
  const userId = userInfo ? +userInfo.id : 0;
  const getWorkUnit = useGetWorkUnit();
  const dataWorkUnit = getWorkUnit.data?.data ? getWorkUnit.data?.data : [];
  const { t } = useTranslation();
  const [showErrorNullFile, setShowErrorNullFile] = useState(false);
  const [detailMecicalFile, setDetailMecicalFile] = useState<FileDetailProps[]>()
  const [detailOtherFile, setDetailOtherFile] = useState<FileDetailProps[]>()
  const [isEdit, setIsEdit] = useState<boolean>(id ? false : true)
  const [isOpenedDeleteBookingConfirmModal, setIsOpenedDeleteBookingConfirmModal] = useState<boolean>(false);
  const [isOpenedFileDeleteConfirmModal, setIsOpenedFileDeleteConfirmModal] = useState(false);
  const [fileId, setFileId] = useState<string>();
  async function getDetailHeathRecord(id: number) {
    const getOrder = await getOrderDetailId(Number(id));
    const { data } = getOrder
    setValue('doctor', data.infoStoreDoctorName)
    setValue('date', dayjs(data.dateTest).format('DD/MM/YYYY'))
    setValue('reasonForExamination', data.reasonForExamination || '')
    const dataFileDetail: FileDetailProps[] = data.listResponseDocumentDTO.documents.map((item, index) => {
      return {
        ...item,
        name: item.fileName,
        img: '',
        file: null
      }
    })
    const mecicalFiles: FileDetailProps[] = dataFileDetail.filter((item, idx) => item.typeExam === 4)
    const otherFiles: FileDetailProps[] = dataFileDetail.filter((item, idx) => item.typeExam !== 4)
    setDetailMecicalFile([...mecicalFiles])
    setDetailOtherFile([...otherFiles])
  }
  useEffect(() => {
    if (id) {
      getDetailHeathRecord(Number(id))
    }
  }, [id])

  useEffect(() => {
    dispatch(setHealthServiceDivision(dataWorkUnit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkUnit.data?.data]);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getUserRelative = useGetUserRelative(+userInfo!.id);
  const dataUserRelative = getUserRelative.data?.data.data.relative
    ? getUserRelative.data?.data.data.relative
    : [];

  const { control, handleSubmit, setValue } = useForm<InfoFormHealthRecord>(
    {
      resolver: yupResolver(
        yup.object().shape({
          date: yup.string().required('MESSAGE_ERROR_CHECKDAY_REQUIRED'),
          doctor: yup.string().required('MESSAGE_ERROR_DOCTOR_REQUIRED'),
          reasonForExamination: yup.string().required('MESSAGE_ERROR_CHECKREASON_REQUIRED'),
        })
      ),
      mode: 'onSubmit',
      defaultValues: {
        date: dayjs().format('DD/MM/YYYY'),
        doctor: "",
        reasonForExamination: '',
      },
    }
  );
  const [fileData, setFileData] = useState<FileDetailProps[]>([]);
  const [mecicalFiles, setMecicalFiles] = useState<FileDetailProps[]>([]);
  const [otherFiles, setOtherFiles] = useState<FileDetailProps[]>([]);

  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [param, setParam] = useState<RecordDataType>();

  const [files, setFiles] = useState<
    {
      id: string;
      fileName: string;
      dowloadUri: string;
    }[]
  >([]);
  const [filesUpload, setFilesUpload] = useState<FileDetailProps[]>([]);
  const [filesUploadExist, setFilesUploadExist] = useState<FileDetailProps[]>([]);


  const onSubmit: SubmitHandler<InfoFormHealthRecord> = async data => {
    const checkAllFileNull = fileData.every(item => item.file === null);
    if (checkAllFileNull) {
      //setShowErrorNullFile(true);
      //return;
    }
    const param: RecordDataType = {
      files: fileData,
      recordDataInfo: {
        testDayTime: data.date,
        doctorName: data.doctor,
        reasonForExamination: data.reasonForExamination
      }
    }
    setParam(param);
    setIsShowConfirm(true);
  };

  const handleMecicalFilesChange = (data: FileDetailProps[]) => {
    const checkAllFileNull = fileData.every(item => item.file === null);
    if (checkAllFileNull) {
      setShowErrorNullFile(false);
    }
    setMecicalFiles(data);
    setFileData([...data, ...otherFiles]);
  }


  const handleOtherFilesChange = (data: FileDetailProps[]) => {
    const checkAllFileNull = fileData.every(item => item.file === null);
    if (checkAllFileNull) {
      setShowErrorNullFile(false);
    }
    setOtherFiles(data);
    setFileData([...mecicalFiles, ...data]);
  }


  const listBtnEdit = (
    <ButtonContainer>
      <Button
        type="button"
        modifiers="register"
        onClick={() => {
          history.back();
        }}
      >
        {t('exam.return')}
      </Button>
      <Button
        type="button"
        modifiers="register"
        onClick={() => { getDetailHeathRecord(Number(id)), setIsEdit(false) }}
      >
        {t('test.cancel-change')}
      </Button>
      <Button type='submit' modifiers="login">
        {t('test.save')}
      </Button>
      <Button
        type="button"
        modifiers="delete"
        onClick={() => {
          setIsOpenedDeleteBookingConfirmModal(true)
        }}
      >
        {t('exam.delete')}
      </Button>
    </ButtonContainer>
  )
  const listBtnNoEdit = (
    <ButtonContainer>
      <Button
        type="button"
        modifiers="register"
        onClick={() => {
          history.back();
        }}
      >
        {t('exam.return')}
      </Button>
      <Button
        type="button"
        modifiers="edit"
        onClick={(e: any) => {
          e.preventDefault();
          setIsEdit(true)
        }
        }
      >
        {t('test.edit')}
      </Button>
      <Button
        type="button"
        modifiers="delete"
        onClick={() => {
          setIsOpenedDeleteBookingConfirmModal(true)
        }}
      >
        {t('exam.delete')}
      </Button>
    </ButtonContainer >
  )

  const listBtnNoEditDefault = (
    <ButtonContainer>
      <Button
        type="button"
        modifiers="register"
        onClick={() => {
          history.back();
        }}
      >
        {t('exam.return')}
      </Button>
    </ButtonContainer>
  )

  const deleteDocument = useDeleteDocument({
    mutation: {
      useErrorBoundary: false,
      onSuccess: ({ data }) => {
        const dataUpload: any = data.data.documents;
        setFiles(data.data.documents);
        setFilesUpload([...dataUpload]);
        setFilesUploadExist([...dataUpload]);
        // setFilesUpload([]);
        NotifyToast(<Toaster>{t('MESSAGES_FILE_DELETE_SUCCESS')}</Toaster>, 'success');
        getDetailHeathRecord(Number(id));
      },
      onError: () => {
        NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
      },
    },
  });

  const handleDeleteFile = useCallback(
    async (fileId: string) => {
      if (deleteDocument.isLoading) return;

      // eslint-disable-next-line no-alert
      await deleteDocument.mutateAsync({ orderId: Number(id), fileId: fileId });

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteDocument.isLoading, deleteDocument.isSuccess]
  );

  const deleteOrderId = useDeleteOrderId({
    mutation: {
      useErrorBoundary: false,
      onSuccess: () => {
        NotifyToast(<Toaster>{t('MESSAGE_DELETE_RECORD_SUCCESS')}</Toaster>, 'success');
        history.back();
      },
      onError: () => {
        NotifyToast(<Toaster>{t('MESSAGE_DELETE_RECORD_ERROR')}</Toaster>, 'error');
      },
    },
  });
  const handledeleteOrder = useCallback(
    async (userId: number, orderId: number) => {
      if (deleteOrderId.isLoading) return;

      await deleteOrderId.mutateAsync({ userId, orderId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteOrderId.isLoading, deleteOrderId.isSuccess]
  );
  const deleteBooking = () => {
    // eslint-disable-next-line no-alert
    if (Number(id) != 0) {
      handledeleteOrder(userId, Number(id));
    }
  };

  const handleDelete = useCallback((fileId: string) => {
    if (fileId) setFileId(fileId);
    setIsOpenedFileDeleteConfirmModal(true);
  }, []);

  return (
    <>
      <form className="o-health-record-form" onSubmit={handleSubmit(onSubmit)}>
        <FormHeading>{t('record.record')}</FormHeading>
        <Heading level="h2" textColor="color-blue" alignLeft>
          <Order>I.</Order>
          {t('record.exam-info')}
        </Heading>
        <div className="o-health-care-form__family">
          <ul className="o-form-family__input--list">
            <li className="o-health-care-form__input-item">
              <Controller
                name={"date"}
                control={control}
                render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => (
                  <div>
                    <DateInput
                      disabled={!isEdit}
                      refCtrl={ref}
                      content={String(t('record.examination-date'))}
                      placeholder={String(t('record.select-day'))}
                      required
                      maxDate={new Date()}
                      name={name}
                      value={value}
                      onChange={e => onChange(e?.toString())}
                      errorMessage={error?.message}
                    />
                  </div>
                )}
              />
            </li>
            <li className="o-health-care-form__input-item">
              <Controller
                name={"doctor"}
                control={control}
                render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => (
                  <Input
                    disabled={!isEdit}
                    ref={ref}
                    label={String(t('record.fullname.doctor'))}
                    placeholder={String(t('record.fullname.doctor'))}
                    name={name}
                    onChange={onChange}
                    value={value}
                    required
                    errorMessage={error?.message}
                  />
                )}
              />
            </li>
          </ul>
          <div className='o-health-record-form_reasonForExamination'>
            <Controller
              name={"reasonForExamination"}
              control={control}
              render={({ field: { onChange, name, value, ref }, fieldState: { error } }) => (
                <Textarea
                  disabled={!isEdit}
                  title={t('record.reason')}
                  placeholder={String(t('record.enter-reason'))}
                  onChange={onChange}
                  name={name}
                  value={value}
                  required
                  errorMessage={error?.message}
                />
              )}
            />
          </div>
          <div className='o-health-record-form_mecical-file'>
            <label htmlFor="">{t('home.prescription')}</label>
            <Upload
              maxSize={5}
              name=''
              data={detailMecicalFile}
              typeExam={[4]}
              onChange={handleMecicalFilesChange}
              clearFileFromServer={handleDelete}
              disable={!isEdit}
            />
            <span style={{ marginTop: '20px', fontSize: '16px', display: 'block' }}>
              *** {t('detail.size-of-5-prescription')}.
            </span>
          </div>
        </div>
        <Heading
          level="h2" textColor="color-blue" alignLeft>
          <Order>II.</Order>
          {t('record.documents')}
        </Heading>
        <Upload
          maxSize={5}
          name=''
          data={detailOtherFile}
          onChange={handleOtherFilesChange}
          disable={!isEdit}
          clearFileFromServer={handleDelete}
        />
        <div className='o-health-record-form__input-file-error'>{showErrorNullFile && <span>{t('record.file-required')}</span>}</div>
        <span style={{ marginTop: '20px', fontSize: '16px', display: 'block' }}>
          *** {t('detail.size-of-5')}.
        </span>
        {id ?
          (isEdit ? listBtnEdit : canEdit ? listBtnNoEdit : listBtnNoEditDefault) :
          <ButtonContainer>
            <Button
              modifiers="register"
              onClick={
                () => {
                  navigate(PATHES['HOME_PAGE']);
                }}
            >
              {t('exam.return')}
            </Button>
            <Button type="submit" modifiers="login">
              {t('record.save')}
            </Button>
          </ButtonContainer >
        }
      </form >
      {isShowConfirm && <ChooseTypeExam setIsShowConfirm={setIsShowConfirm} param={param} userId={userInfo!.id} orderId={id ? Number(id) : undefined} getDetailHeathRecord={() => getDetailHeathRecord(Number(id))} setIsEdit={setIsEdit} />}
      <Loading
        open={
          getWorkUnit.isLoading ||
          getUserRelative.isLoading
        }
      />
      {isOpenedDeleteBookingConfirmModal && (
        <ConfirmAlert
          handleCloseRequest={() => setIsOpenedDeleteBookingConfirmModal(false)}
          title={String(t('exam.confirm-delete-record'))}
          cancelButton={
            <Button
              modifiers="delete"
              type="button"
              onClick={() => setIsOpenedDeleteBookingConfirmModal(false)}
            >
              {t('list-patient.cancel')}
            </Button>
          }
          submitButton={
            <Button
              modifiers="login"
              type="button"
              onClick={() => {
                setIsOpenedDeleteBookingConfirmModal(false);
                deleteBooking();
              }}
            >
              {t('list-patient.confirm')}
            </Button>
          }
        >
          {t('MESSAGES_HEATH_RECORD_DELETE_CONFIRM')}
        </ConfirmAlert>


      )}

      {isOpenedFileDeleteConfirmModal && (
        <ConfirmAlert
          handleCloseRequest={() => setIsOpenedFileDeleteConfirmModal(false)}
          title={String(t('exam.confirm-delelte-file'))}
          cancelButton={
            <Button
              modifiers="delete"
              type="button"
              onClick={() => setIsOpenedFileDeleteConfirmModal(false)}
            >
              {t('list-patient.cancel')}
            </Button>
          }
          submitButton={
            <Button
              modifiers="login"
              type="button"
              onClick={() => {
                setIsOpenedFileDeleteConfirmModal(false);
                if (fileId) handleDeleteFile(fileId);
              }}
            >
              {t('list-patient.confirm')}
            </Button>
          }
        >
          {t('MESSAGES_FILE_DELETE_CONFIRM')}
        </ConfirmAlert>
      )}
    </>
  );
};


type ChooseTypeExamProps = {
  setIsShowConfirm: (value: boolean) => void,
  getDetailHeathRecord: (id: number) => void,
  setIsEdit: (value: boolean) => void;
  param: RecordDataType | undefined;
  userId: number | undefined;
  orderId: number | undefined;
}

const ChooseTypeExam: React.FC<ChooseTypeExamProps> = ({ setIsShowConfirm, userId, param, orderId, getDetailHeathRecord, setIsEdit }) => {
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleConfirm = async () => {

    const maxFileSizeInBytes = 10 * 1024 * 1024; // 10MB


    const totalFileSize = param?.files.reduce((acc, fileDetail) => {
      return acc + (fileDetail.file ? fileDetail.file.size : 0);
    }, 0);

    if (totalFileSize && totalFileSize > maxFileSizeInBytes) {
      NotifyToast(<Toaster>{t('detail.size-of-5-count')}</Toaster>, 'error');
      return;
    }

    if (!orderId) {
      if (!userId) {
        NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
        return;
      };
      if (!param) {
        return;
      }
      if (isUploading) return;
      setIsUploading(true);
      await postHealthRecord(userId, param)
        .then(res => {
          NotifyToast(<Toaster>{t('MESSAGE_SAVE_HEATH_RECORD_SUCCESS')}</Toaster>, 'success');
          setTimeout(() => {
            navigate(PATHES['HOME_PAGE']);
          }, 2000);
        })
        .catch(err => {
          NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
        });
      setIsShowConfirm(false);
    } else {
      if (!userId) {

        NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
        return;
      };
      if (!param) {
        return;
      }
      if (isUploading) return;
      setIsUploading(true);

      await putHealthRecord(userId, orderId, param)
        .then(res => {
          NotifyToast(<Toaster>{t('MESSAGE_SAVE_HEATH_RECORD_SUCCESS')}</Toaster>, 'success');
          getDetailHeathRecord(Number(orderId));
          setIsEdit(false);
        })
        .catch(err => {
          NotifyToast(<Toaster>{t('MESSAGE_CALL_API_FAIL')}</Toaster>, 'error');
        });
      setIsShowConfirm(false);
    }
  }
  return (
    <div className="c-choose-type-exam">
      <div className="c-choose-type-exam__content">
        <div className="c-choose-type-exam__title">
          <span>{t('record.confirm-save-data')}</span>
          <i className="fa fa-times btn-close" onClick={() => setIsShowConfirm(false)}></i>
        </div>
        <div className="c-confirm-save-data">
          <p className="row">
            <span className="title">{t('record.fullname.doctor')} : </span><span className='content'>{param?.recordDataInfo.doctorName}</span>
          </p>
          <p className="row">
            <span className="title">{t('record.examination-date')} : </span><span className='content'>{param?.recordDataInfo.testDayTime}</span>
          </p>
          <p className="row">
            <span className="title">{t('record.reason')} : </span><span className='content'>{param?.recordDataInfo.reasonForExamination}</span>
          </p>
          <p className="row">
            <span className="title"> {t('record.documents')} :</span>
          </p>
          <ul className="doc-list">
            {param?.files.map((item, index) => {
              if (item.file === null) return;
              return (
                <li key={index}>
                  <span className='doc-list__title'>
                    + {
                      item.typeExam === 1 ? t('home.test')
                        : item.typeExam === 2 ? t('home.image')
                          : item.typeExam === 4 ? t('home.prescription')
                            : t('info.other')
                    }
                    : </span>
                  <span className='doc-list__file-name'>{item.file.name}</span>
                </li>
              )
            })}
          </ul>
        </div>
        <ButtonContainer>
          <Button onClick={() => setIsShowConfirm(false)} modifiers='delete'>{t('monitoring-health.cancel')}</Button>
          <Button onClick={handleConfirm} modifiers="login">{t('monitoring-health.confirm')}</Button>
        </ButtonContainer>
      </div>
    </div>
  );
}

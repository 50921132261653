import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state

interface ForgetPasswordState {
  messengerPhone: string;
}

// Define the initial state using that type
const initialState: ForgetPasswordState = {
  messengerPhone: '',
};

export const forgetPasswordSlice = createSlice({
  name: 'forgetpassword',
  // `infoBookingSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`

    setMessengerPhone: (state, action) => {
      state.messengerPhone = action.payload;
    },
  },
});

export const { setMessengerPhone } = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

interface ICustomer {
  id: string;
  name: string;
  phone: string;
  gender: string;
  birthDay: string;
  email: string;
  address: string;
  created_by: string;
}

// Define a type for the slice state
interface CustomerInfoState {
  customerUser: ICustomer;
  id: string;
  infoCustomer: ICustomer;
}

// Define the initial state using that type
const initialState: CustomerInfoState = {
  id: '',
  infoCustomer: {
    id: '',
    name: '',
    phone: '',
    gender: 'male',
    birthDay: '',
    email: '',
    address: '',
    created_by: '',
  },
  customerUser: {
    id: '',
    name: '',
    phone: '',
    gender: 'male',
    birthDay: '',
    email: '',
    address: '',
    created_by: '',
  },
};

export const userInfoSlice = createSlice({
  name: 'customerinfo',
  // `userInfoSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    loadIdCustomer: (state, action) => {
      state.id = action.payload;
    },

    loadInfoCustomers: (state, action) => {
      state.infoCustomer = action.payload;
    },

    loadCustomerUsers: (state, action) => {
      state.customerUser = action.payload;
    },
  },
});

export const { loadInfoCustomers, loadIdCustomer, loadCustomerUsers } = userInfoSlice.actions;

export default userInfoSlice.reducer;

import React from 'react';
import { mapModifiers } from 'src/libs/component';

export const ICON_SHAPES = [
  'add',
  'add-inactive',
  'zoom_in',
  'zoom_out',
  'close',
  'notification',
  'search',
  'x',
] as const;

export type IconShape = typeof ICON_SHAPES[number];

export interface IconProps {
  name: IconShape;
  onClick?: (e?: any) => void;
}

export const Icon: React.FC<IconProps> = ({ name, onClick }) => (
  <i className={mapModifiers('a-icon', name)} onClick={onClick} />
);

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AsyncReturnType, HttpResponseObject, OrderRequest, OrderRequestObject } from './models/common';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import {
  OrderDetailAllRequest,
  OrderDetailIdRequest,
  OrderExport,
  OrderPutRequest,
  SendMailRequestObject,
  OrderDetailObject,
  FileUploadObject,
  FileOfOrderObject,
  OrdersOfPatientByMonthYearResponse,
  OrdersOfPatientByMonthYearRequest,
  DiagHistoryResponse,
  ScheduleInMonthRequest,
  ScheduleInMonthResponse,
  HistoryTestByOrderAndDateResponse,
  HistoryImageByOrderAndDateResponse,
  ReBookingInfoObjectResponse,
  ListBookingOrderByMonthHistoryResponse,
  PrivilegeRelativeResponse,
  OrderDetail,
  RecordDataType,
  UnitConversionRequest,
  UpdateNoteAfterComplete,
} from './models/order';
import { IHealthCareBookingSuccess } from 'src/redux/features/health-care-slice';
import {
  DiseaseInfoResponse,
  ExamInfoWithPrescriptionObjectResponse,
  HistoryExaminationInformationResponse,
  HistoryExaminationResponse,
  HistoryExaminationResultResponse,
  HistoryImageResponse,
  HistoryTestResponse,
} from './models/prescription';
import i18n from 'src/utils/translate/config';
import { FileDetailProps } from 'src/components/atoms/upload';

export const postHealthCareOrder = <TData = AxiosResponse<IHealthCareBookingSuccess[]>>(
  id: number,
  param: OrderRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/order/${id}`, param, options);
};

export const usePostHealthCareOrder = <
  TData = AsyncReturnType<typeof postHealthCareOrder, AxiosResponse<IHealthCareBookingSuccess[]>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; data: OrderRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; data: OrderRequest }, TContext>(props => {
    const { id, data } = props || {};

    return postHealthCareOrder<TData>(id, data, axiosOptions);
  }, mutationOptions);
};

export const postOrder = <TData = AxiosResponse<OrderRequest>>(
  id: number,
  param: OrderRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/order/${id}`, param, options);
};

export const usePostOrder = <
  TData = AsyncReturnType<typeof postOrder, AxiosResponse<OrderRequest>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; data: OrderRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; data: OrderRequest }, TContext>(props => {
    const { id, data } = props || {};

    return postOrder<TData>(id, data, axiosOptions);
  }, mutationOptions);
};

export const getOrderInModal = <TData = AxiosResponse<OrderRequest>>(
  id: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/${id}/${orderId}`, options);
};

export const useGetOrderInModal = <
  TData = AsyncReturnType<typeof getOrderInModal, AxiosResponse<OrderRequest>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; orderId: number }, TContext>(props => {
    const { id, orderId } = props || {};

    return getOrderInModal<TData>(id, orderId, axiosOptions);
  }, mutationOptions);
};

export const postOrderInModal = <TData = AxiosResponse<OrderRequest>>(
  id: number,
  orderId: number,
  param: OrderRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/order/${id}/${orderId}`, param, options);
};

export const usePostOrderInModal = <
  TData = AsyncReturnType<typeof postOrderInModal, AxiosResponse<OrderRequest>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; orderId: number; data: OrderRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; orderId: number; data: OrderRequest }, TContext>(props => {
    const { id, data, orderId } = props || {};

    return postOrderInModal<TData>(id, orderId, data, axiosOptions);
  }, mutationOptions);
};

// /order/detail/{id}
export const getDetailOrderId = <TData = AxiosResponse<OrderDetailAllRequest>>(
  params: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/detail/${params}`, options);
};

export const getDetailOrderIdQueryKey = (params: number) => [`/order/detail/${params}`];

export const useGetDetailOrderId = <
  TQueryFnData = AsyncReturnType<typeof getDetailOrderId, AxiosResponse<OrderDetailAllRequest>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  params: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getDetailOrderIdQueryKey(params);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getDetailOrderId<TQueryFnData>(params, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

export const useGetDetailOrderById = <
  TQueryFnData = AsyncReturnType<typeof getDetailOrderId, AxiosResponse<OrderDetail>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  params: string,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = i18n.language ? [`/order/detail/${params}`, i18n.language] : `/order/detail/${params}`;
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getOrderById<TQueryFnData>(params, axiosOptions),
    { ...queryOptions, cacheTime: 0 }
  );

  return { queryKey, ...query };
};

// /order/{id}
export const getOrderById = <TData = AxiosResponse<OrderDetail>>(
  params: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/get-detail-of-order-for-doctor-edit/${params}`, options);
};

// /order/{id}
export const getOrderId = <TData = AxiosResponse<OrderDetailAllRequest>>(
  id: number, // Giữ id
  month = 0, // Tham số month
  year = 0, // Tham số year
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/${id}`, {
    ...options,
    params: { month, year }, // Thêm month và year vào params
  });
};

export const getOrderIdQueryKey = (id: number, month: number, year: number) => [
  `/order/${id}`,
  { month, year },
];

export const useGetOrderId = <
  TQueryFnData = AsyncReturnType<typeof getOrderId, AxiosResponse<OrderDetailAllRequest>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  id: number,
  month = 0,
  year = 0,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getOrderIdQueryKey(id, month, year);

  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getOrderId<TQueryFnData>(id, month, year, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

// /order/detail/{userId}/{orderId}
export const getOrderDetailId = <TData = AxiosResponse<OrderDetailIdRequest>>(
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/detail/${orderId}`, options);
};

export const getOrderDetailIdQueryKey = (orderId: number) => [`/order/detail/${orderId}`];

export const useGetOrderDetailId = <
  TQueryFnData = AsyncReturnType<typeof getOrderDetailId, AxiosResponse<OrderDetailIdRequest>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  orderId: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getOrderDetailIdQueryKey(orderId);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getOrderDetailId<TQueryFnData>(orderId, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

// /order-export/userId/orderId
export const getOrderExport = <TData = AxiosResponse<OrderExport>>(
  userId: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order-export/${userId}/${orderId}/${i18n.language}`, options);
};

export const getOrderExportQueryKey = (userId: number, orderId: number) => [
  `/order-export/${userId}/${orderId}`,
];

export const useGetOrderExport = <
  TQueryFnData = AsyncReturnType<typeof getOrderExport, AxiosResponse<OrderExport>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  userId: number,
  orderId: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  // const { mutation: mutationOptions, axios: axiosOptions } = options || {};
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getOrderExportQueryKey(userId, orderId);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getOrderExport<TQueryFnData>(userId, orderId, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

export const putOrderId = <TData = AxiosResponse<OrderPutRequest>>(
  id: number,
  param: OrderRequestObject,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.put(`/order/${id}`, param, options);
};

export const usePutOrderId = <
  TData = AsyncReturnType<typeof putOrderId, AxiosResponse<OrderPutRequest>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; data: OrderRequestObject }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; data: OrderRequestObject }, TContext>(props => {
    const { id, data } = props || {};

    return putOrderId<TData>(id, data, axiosOptions);
  }, mutationOptions);
};

export const patchOrderId = <TData = AxiosResponse<OrderPutRequest>>(
  userId: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.patch(`/order/cancel/${userId}/${orderId}`, options);
};

export const usePatchOrderId = <
  TData = AsyncReturnType<typeof patchOrderId, AxiosResponse<OrderPutRequest>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number; orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number; orderId: number }, TContext>(props => {
    const { userId, orderId } = props || {};

    return patchOrderId<TData>(userId, orderId, axiosOptions);
  }, mutationOptions);
};

const patchCompleteOrderId = <TData = AxiosResponse>(
  userId: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.patch(`/order/complete/${userId}/${orderId}`, options);
};

const deleteOrderId = <TData = AxiosResponse>(
  userId: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.delete(`/order/delete/${userId}/${orderId}`, options);
};

export const usePatchCompleteOrderId = <
  TData = AsyncReturnType<typeof patchCompleteOrderId, AxiosResponse>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number; orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number; orderId: number }, TContext>(props => {
    const { userId, orderId } = props || {};

    return patchCompleteOrderId<TData>(userId, orderId, axiosOptions);
  }, mutationOptions);
};

export const useDeleteOrderId = <
  TData = AsyncReturnType<typeof deleteOrderId, AxiosResponse>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number; orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number; orderId: number }, TContext>(props => {
    const { userId, orderId } = props || {};

    return deleteOrderId<TData>(userId, orderId, axiosOptions);
  }, mutationOptions);
};

// /doctor-schedule/{id}
export const getDoctorSchedule = <TData = AxiosResponse<OrderDetailAllRequest>>(
  params: number, // Giữ params (id của bác sĩ)
  month = 0, // Tham số month
  year = 0, // Tham số year
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/doctor-schedule/${params}`, {
    ...options,
    params: { month, year }, // Thêm month và year vào params
  });
};

const getDoctorScheduleQueryKey = (params: number, month: number, year: number) => [
  `/doctor-schedule/${params}`,
  { month, year },
];

export const useGetDoctorSchedule = <
  TQueryFnData = AsyncReturnType<typeof getDoctorSchedule, AxiosResponse<OrderDetailAllRequest>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  params: number,
  month = 0,
  year = 0,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getDoctorScheduleQueryKey(params, month, year); // Include month and year in query key
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getDoctorSchedule<TQueryFnData>(params, month, year, axiosOptions), // Pass month and year to the function
    queryOptions
  );

  return { queryKey, ...query };
};

export const getOrderSuccessId = <TData = AxiosResponse<OrderDetailObject>>(
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/order/detail/${orderId}`, options);
};

export const useGetOrderSuccessId = <
  TData = AsyncReturnType<typeof getOrderSuccessId, AxiosResponse<OrderDetailObject>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getOrderSuccessId<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

export const postSendEmail = <TData = AxiosResponse<void>>(
  param: SendMailRequestObject,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post('/sent-mail', param, options);
};

export const usePostSendEmail = <
  TData = AsyncReturnType<typeof postSendEmail, AxiosResponse<void>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { param: SendMailRequestObject }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { param: SendMailRequestObject }, TContext>(props => {
    const { param } = props || {};

    return postSendEmail<TData>(param, axiosOptions);
  }, mutationOptions);
};

const getFileOfOrder = <TData = AxiosResponse<FileOfOrderObject>>(
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/document/${orderId}`, options);
};

const getFileOfOrderQueryKey = (orderId: number) => [`/order-export/${orderId}/${i18n.language}`];

export const useGetFileOfOrder = <
  TQueryFnData = AsyncReturnType<typeof getFileOfOrder, AxiosResponse<FileOfOrderObject>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  orderId: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getFileOfOrderQueryKey(orderId);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getFileOfOrder<TQueryFnData>(orderId, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

export const postHealthRecord = <TData = AxiosResponse<FileOfOrderObject>>(
  userId: number,
  param: RecordDataType,
  options?: AxiosRequestConfig
): Promise<TData> => {
  const formData = new FormData();

  type FileInfo = {
    fileIndex: number;
    typeExam: number;
    displayName: string;
  };
  const fileInfos: FileInfo[] = [];
  const dataPayload: FileDetailProps[] = param.files;
  let index = 0;
  dataPayload.map((dataUpload: FileDetailProps) => {
    if (dataUpload.file) {
      formData.append('files', dataUpload.file);
      fileInfos.push({
        fileIndex: index,
        typeExam: dataUpload.typeExam,
        displayName: dataUpload.displayName,
      });
      index++;
    }
  });

  const jsonFileInfo = JSON.stringify(fileInfos);
  formData.append('fileInfos', new Blob([jsonFileInfo], { type: 'application/json' }));

  // Thông tin lưu trữ hồ sơ
  const recordDataInfo = JSON.stringify(param.recordDataInfo);
  formData.append('recordDataInfo', new Blob([recordDataInfo], { type: 'application/json' }));

  return axios.post(`/order-record-data/${userId}`, formData, {
    ...options,
    headers: {
      ...options?.headers,
      'Content-Type': 'multipart/form-data',
    },
  });

  // return axios.post(`/order-record-data/${userId}`, formData, options);
};

export const putHealthRecord = <TData = AxiosResponse<FileOfOrderObject>>(
  userId: number,
  orderId: number,
  param: RecordDataType,
  options?: AxiosRequestConfig
): Promise<TData> => {
  const formData = new FormData();

  type FileInfo = {
    fileIndex: number;
    typeExam: number;
    displayName: string;
  };
  const fileInfos: FileInfo[] = [];
  const dataPayload: FileDetailProps[] = param.files;
  let index = 0;
  dataPayload.map((dataUpload: FileDetailProps) => {
    if (dataUpload.file) {
      formData.append('files', dataUpload.file);
      fileInfos.push({
        fileIndex: index,
        typeExam: dataUpload.typeExam,
        displayName: dataUpload.displayName,
      });
      index++;
    }
  });

  const jsonFileInfo = JSON.stringify(fileInfos);
  formData.append('fileInfos', new Blob([jsonFileInfo], { type: 'application/json' }));

  // Thông tin lưu trữ hồ sơ
  const recordDataInfo = JSON.stringify(param.recordDataInfo);
  formData.append('recordDataInfo', new Blob([recordDataInfo], { type: 'application/json' }));

  return axios.put(`/order-record-data/${userId}/${orderId}`, formData, {
    ...options,
    headers: {
      ...options?.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const postDocument = <TData = AxiosResponse<FileOfOrderObject>>(
  patientId: string,
  doctorId: string,
  param: FileUploadObject,
  options?: AxiosRequestConfig
): Promise<TData> => {
  const formData = new FormData();

  const dataPayload: FileDetailProps[] = param.files;
  dataPayload.map((dataUpload: FileDetailProps) => {
    //formData.append('files', file);

    if (dataUpload.file) {
      formData.append('files', dataUpload.file);
      formData.append('typeExams', dataUpload.typeExam.toString());
      formData.append('ids', 'unavailable');
      formData.append('fileIds', 'unavailable');
      formData.append('names', dataUpload.name);
      formData.append('displayNames', dataUpload.displayName);
    } else {
      formData.append('files', new Blob());
      formData.append('typeExams', dataUpload.typeExam.toString());
      if (dataUpload.id) {
        formData.append('ids', dataUpload.id);
      }
      if (dataUpload.fileId) {
        formData.append('fileIds', dataUpload.fileId);
      }
      formData.append('names', dataUpload.name);
      formData.append('displayNames', dataUpload.displayName);
    }
  });

  return axios.post(
    `/document/${param.orderId}${
      patientId && doctorId ? `?doctorId=${doctorId}&patientId=${patientId}` : ''
    }`,
    formData,
    options
  );
};

export const usePostDocument = <
  TData = AsyncReturnType<typeof postDocument, AxiosResponse<FileOfOrderObject>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { param: FileUploadObject }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<
    TData,
    TError,
    { doctorId: string; patientId: string; param: FileUploadObject },
    TContext
  >(props => {
    const { doctorId, patientId, param } = props || {};

    return postDocument<TData>(patientId, doctorId, param, axiosOptions);
  }, mutationOptions);
};

const deleteDocument = <TData = AxiosResponse<FileOfOrderObject>>(
  orderId: number,
  fileId: string,
  options?: AxiosRequestConfig
): Promise<TData> => axios.delete(`/document/${orderId}/${fileId}`, options);

export const useDeleteDocument = <
  TData = AsyncReturnType<typeof postDocument, AxiosResponse<FileOfOrderObject>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number; fileId: string }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number; fileId: string }, TContext>(props => {
    const { orderId, fileId } = props || {};

    return deleteDocument<TData>(orderId, fileId, axiosOptions);
  }, mutationOptions);
};

const getExamInfo = <TData = AxiosResponse<ExamInfoWithPrescriptionObjectResponse>>(
  infoId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/exam-info-prescription/${infoId}`, options);

export const useGetExamInfo = <
  TData = AsyncReturnType<typeof getExamInfo, AxiosResponse<ExamInfoWithPrescriptionObjectResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getExamInfo<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getDiseaseInfo = <TData = AxiosResponse<DiseaseInfoResponse>>(
  infoId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/disease-info/${infoId}`, options);

export const useGetDiseaseInfo = <
  TData = AsyncReturnType<typeof getDiseaseInfo, AxiosResponse<DiseaseInfoResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getDiseaseInfo<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getHistoryExamination = <TData = AxiosResponse<HistoryExaminationResponse>>(
  patientId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-examination/${patientId}`, options);

export const useGetHistoryExamination = <
  TData = AsyncReturnType<typeof getHistoryExamination, AxiosResponse<HistoryExaminationResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getHistoryExamination<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getHistoryExaminationInformation = <TData = AxiosResponse<HistoryExaminationInformationResponse>>(
  patientId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-examination-information/${patientId}`, options);

const getHistoryExaminationInformationQueryKey = (patientId: number) => [
  `/history-examination-information/${patientId}`,
];

export const useGetHistoryExaminationInformation = <
  TQueryFnData = AsyncReturnType<
    typeof getHistoryExaminationInformation,
    AxiosResponse<HistoryExaminationInformationResponse>
  >,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  patientId: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = i18n.language
    ? [getHistoryExaminationInformationQueryKey(patientId), i18n.language]
    : getHistoryExaminationInformationQueryKey(patientId);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getHistoryExaminationInformation<TQueryFnData>(patientId, axiosOptions),
    { ...queryOptions, cacheTime: 0 }
  );

  return { queryKey, ...query };
};

export const getHistoryExaminationResult = <TData = AxiosResponse<HistoryExaminationResultResponse>>(
  patientId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/examination-result-in-time-range/${patientId}`, options);

const getHistoryTest = <TData = AxiosResponse<HistoryTestResponse>>(
  patientId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-test/${patientId}`, options);

export const useGetHistoryTest = <
  TData = AsyncReturnType<typeof getHistoryTest, AxiosResponse<HistoryTestResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getHistoryTest<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getHistoryImage = <TData = AxiosResponse<HistoryImageResponse>>(
  patientId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-image/${patientId}`, options);

export const useGetHistoryImage = <
  TData = AsyncReturnType<typeof getHistoryImage, AxiosResponse<HistoryImageResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getHistoryImage<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const postOrdersOfPatientByMonthYear = <TData = AxiosResponse<OrdersOfPatientByMonthYearResponse>>(
  doctorId: number,
  param: OrdersOfPatientByMonthYearRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/patients/${doctorId}`, param, options);
};

export const usePostOrdersOfPatientByMonthYear = <
  TData = AsyncReturnType<
    typeof postOrdersOfPatientByMonthYear,
    AxiosResponse<OrdersOfPatientByMonthYearResponse>
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number; param: OrdersOfPatientByMonthYearRequest }, TContext>(
    props => {
      const { orderId, param } = props || {};

      return postOrdersOfPatientByMonthYear<TData>(orderId, param, axiosOptions);
    },
    mutationOptions
  );
};

const getDiagHistoryDetails = <TData = AxiosResponse<DiagHistoryResponse>>(
  infoId: number,
  categoryItemId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/total-test-detail/${infoId}/${categoryItemId}`, options);
};

export const getPrivilegeRelative = <TData = AxiosResponse<PrivilegeRelativeResponse>>(
  infoId: number,
  userId: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/privilege-relative/privileges/${infoId}/${userId}`, options);
};

export const useGetPrivilegeRelative = <
  TData = AsyncReturnType<typeof getPrivilegeRelative, AxiosResponse<PrivilegeRelativeResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { infoId: number; userId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { infoId: number; userId: number }, TContext>(props => {
    const { infoId, userId } = props || {};

    return getPrivilegeRelative<TData>(infoId, userId, axiosOptions);
  }, mutationOptions);
};

export const useGetDiagHistoryDetails = <
  TData = AsyncReturnType<typeof getDiseaseInfo, AxiosResponse<DiagHistoryResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { infoId: number; categoryItemId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { infoId: number; categoryItemId: number }, TContext>(props => {
    const { infoId, categoryItemId } = props || {};

    return getDiagHistoryDetails<TData>(infoId, categoryItemId, axiosOptions);
  }, mutationOptions);
};

export const postScheduleInMonth = <TData = AxiosResponse<ScheduleInMonthResponse>>(
  param: ScheduleInMonthRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post('/schedule-in-month/', param, options);

export const usePostScheduleInMonth = <
  TData = AsyncReturnType<typeof postScheduleInMonth, AxiosResponse<ScheduleInMonthResponse>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { param: ScheduleInMonthRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { param: ScheduleInMonthRequest }, TContext>(props => {
    const { param } = props || {};

    return postScheduleInMonth<TData>(param, axiosOptions);
  }, mutationOptions);
};

const getHistoryTestByOrderAndDate = <TData = AxiosResponse<HistoryTestByOrderAndDateResponse>>(
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-test-by-order/${orderId}`, options);

export const useGetHistoryTestByOrderAndDate = <
  TData = AsyncReturnType<
    typeof getHistoryTestByOrderAndDate,
    AxiosResponse<HistoryTestByOrderAndDateResponse>
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getHistoryTestByOrderAndDate<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getHistoryImageByOrderAndDate = <TData = AxiosResponse<HistoryImageByOrderAndDateResponse>>(
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/history-image-by-order/${orderId}`, options);

export const useGetHistoryImageByOrderAndDate = <
  TData = AsyncReturnType<
    typeof getHistoryImageByOrderAndDate,
    AxiosResponse<HistoryImageByOrderAndDateResponse>
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { orderId: number }, TContext>(props => {
    const { orderId } = props || {};

    return getHistoryImageByOrderAndDate<TData>(orderId, axiosOptions);
  }, mutationOptions);
};

const getReBookingOrder = <TData = AxiosResponse<ReBookingInfoObjectResponse>>(
  userId: number,
  orderId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/order/${userId}/${orderId}`, options);

export const useGetReBookingOrder = <
  TData = AsyncReturnType<typeof getReBookingOrder, AxiosResponse<ReBookingInfoObjectResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number; orderId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number; orderId: number }, TContext>(props => {
    const { userId, orderId } = props || {};

    return getReBookingOrder<TData>(userId, orderId, axiosOptions);
  }, mutationOptions);
};

const postListBookingOrderByMonth = <TData = AxiosResponse<ListBookingOrderByMonthHistoryResponse>>(
  userId: number,
  param: OrdersOfPatientByMonthYearRequest,
  options?: AxiosRequestConfig
): Promise<TData> => axios.post(`/list-orders/${userId}`, param, options);

export const usePostListBookingOrderByMonth = <
  TData = AsyncReturnType<
    typeof postListBookingOrderByMonth,
    AxiosResponse<ListBookingOrderByMonthHistoryResponse>
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { userId: number; param: OrdersOfPatientByMonthYearRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number; param: OrdersOfPatientByMonthYearRequest }, TContext>(
    props => {
      const { userId, param } = props || {};

      return postListBookingOrderByMonth<TData>(userId, param, axiosOptions);
    },
    mutationOptions
  );
};

export const convertOrderDetailValueApi = async <TData = number[]>(
  conversionRequest: UnitConversionRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TData>> => {
  return axios.post<TData>('/convert', conversionRequest, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });}
  
  export const updateNoteAfterComplete = async (orderId: number, noteAfterComplete: string) => {
    return axios.put(`/order/${orderId}/note`, noteAfterComplete, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  };
import { detect } from 'detect-browser';

const browser = detect();

const PRODUCTION_HOST = 'www.royalhealthcare.asia';
const PRODUCTION_HOST_EXTRA = 'royalhealthcare.asia';
const HOST_DEV = 'release-netlify--royal-heath-care-frontend.netlify.app';
const LOCAL_HOST = 'localhost:3000';

const IS_PRODUCTION = location.host === PRODUCTION_HOST || location.host === PRODUCTION_HOST_EXTRA;
const IS_DEV = location.host === HOST_DEV;
const IS_LOCAL = location.origin === `http://${LOCAL_HOST}`;
const IS_SAFARI = browser?.name === 'safari';
const IS_WINDOWS = browser?.os?.match(/Windows/);

/**
 * ENV provides values related to NODE_ENV; user-agent; etc...
 */
export const ENV = {
  IS_PRODUCTION,
  IS_DEV,
  IS_LOCAL,
  IS_SAFARI,
  IS_WINDOWS,
} as const;

import React from 'react';
import PATHES from 'src/constants/pathes';

import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { loadFromCookies } from 'src/libs/utils';

const PrivateRoute: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.authSlice);
  const accessToken = loadFromCookies('access_token', true);

  if (!!userInfo?.fullName && accessToken) {
    return <Outlet />;
  }

  return <Navigate to={PATHES['LOGIN_PAGE']} replace />;
};

export default PrivateRoute;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapModifiers } from 'src/libs/component';

export interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'className'> {
  title: string;
  required?: boolean;
  errorMessage?: string;
  modifiers?: 'search';
  className?: string;
  noWrap?: boolean;
  ref?: React.Ref<HTMLSelectElement>;
}

export const Select: React.FC<SelectProps> = React.forwardRef(
  ({ children, title, required, modifiers, errorMessage, className, noWrap, ...selectProps }, ref) => {
    const { t } = useTranslation();
    return (
      <div className={mapModifiers('a-select', errorMessage && 'error', noWrap && 'no-wrap', modifiers)}>
        {noWrap && (
          <div className="a-select__title-noWrap">
            <label>{title}</label>
            {required ? <span className="a-select__requied">{t('common.mandatory')}</span> : null}
          </div>
        )}
        <select {...selectProps} className={`a-select__input ${className ? className : ''}`} ref={ref}>
          {children}
        </select>
        {!noWrap && (
          <div className="a-select__title">
            <label>{title}</label>
            {required ? <span className="a-select__requied">{t('common.mandatory')}</span> : null}
          </div>
        )}
        {errorMessage && <p className="a-select__error-message">{t(errorMessage)}</p>}
      </div>
    );
  }
);

Select.displayName = 'Select';

export interface OptionGroupProps extends Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'className'> {
  label?: string;
  modifiers?: 'select';
}

export const OptionGroup: React.FC<OptionGroupProps> = ({ children, modifiers, label, ...optionProps }) => (
  <optgroup {...optionProps} className={mapModifiers('a-option', modifiers)} label={label}>
    {children}
  </optgroup>
);

export interface OptionProps extends Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'className'> {
  value?: string | number;
  modifiers?: 'select';
}

export const Option: React.FC<OptionProps> = ({ children, modifiers, value, ...optionProps }) => (
  <option {...optionProps} className={mapModifiers('a-option', modifiers)} value={value}>
    {children}
  </option>
);

import { AnyAction } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import customerInfoSliceReducer from './features/customer-info-slice';
import healthCareSliceReducer from './features/health-care-slice';
import diagnosticTestSliceReducer from './features/diagnostic-test-slice';
import imageAnalysationSliceReducer, { ImageAnalysationState } from './features/image-analysation-slice';
import authSliceReducer, { AuthState } from './features/login-slice';
import forgetPasswordSliceReducer from './features/forget-password-slice';
import orderSliceReducer from './features/order-slice';
import registerFormSliceReducer from './features/register-form-slice';
import userInfoSliceReducer from './features/user-info-slice';
import patientSliceReducer from './features/patient-slice';
import healthCareDoctorEditSliceReducer from './features/health-care-edit-doctor-slice';
import diagnosticTestModalReducer from './features/diagnostic-test-modal-slice';
import imageAnalysationModalReducer from './features/image-analysation-modal-slice';
import findPatientSliceReducer from './features/patient-information-slice';

const userInfoPersistConfig: PersistConfig<AuthState> = {
  key: 'userInfo',
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const imageCategoriesPersistConfig: PersistConfig<ImageAnalysationState> = {
  key: 'imageCategories',
  storage: storage,
  whitelist: ['categoryList'],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  customerInfoSlice: customerInfoSliceReducer,
  healthCareSlice: healthCareSliceReducer,
  diagnosticTestSlice: diagnosticTestSliceReducer,
  findPatientSlice: findPatientSliceReducer,
  imageAnalysationSlice: persistReducer<ImageAnalysationState, AnyAction>(
    imageCategoriesPersistConfig,
    imageAnalysationSliceReducer
  ),
  authSlice: persistReducer<AuthState, AnyAction>(userInfoPersistConfig, authSliceReducer),
  forgetPasswordSlice: forgetPasswordSliceReducer,
  orderSlice: orderSliceReducer,
  registerFormSlice: registerFormSliceReducer,
  userInfoSlice: userInfoSliceReducer,
  patientSlice: patientSliceReducer,
  healthCareDoctorEditSlice: healthCareDoctorEditSliceReducer,
  diagnosticTestModalSlice: diagnosticTestModalReducer,
  imageAnalysationModalSlice: imageAnalysationModalReducer,
});

export default rootReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IOrder {
  code: string;
  created_at: string;
  created_id: string;
  order_date: string;
  status_id: string;
  updated_at: string;
  updated_id: string;
  user_id: string;
}

export interface IOrderRequest {
  id: string;
  check_date: string;
  check_hour: string;
  serviecs: string;
  serviecsDetail: string;
  serviecsDetailSelect: string;
  image: Array<string>;
  note: string;
  isFamily: boolean;
}

interface IOrderDetailApi {
  checkDay: string;
  checkHour: string;
  code: string;
  created_at: string;
  created_by: string;
  doctors: string;
  image: string;
  note: string;
  order_id: string;
  reciever_id: string;
  service: string;
  service_detail: string;
  service_detail_select: string;
  special: string;
  updated_at: string;
  updated_by: string;
  isFamily: boolean;
}

export interface IOrderMedicineRequest {
  id: string;
  serviecs: string;
  image: string[];
  note: string;
}

export interface IOrderDetail extends IOrderRequest {
  order_id: string;
  doctorSelect?: string;
  special: string;
}

export interface ImageFile {
  dataImageFile: File;
  dataImageBase64: string;
  dataImageName: string;
}

// Define a type for the slice state
interface OrderState {
  serviecs: string;
  imageList: ImageFile[];
  order_id: string;
  dataOrder: IOrder;
  dataOrderDetail: IOrderDetail;
  dataOrderDetailApi: IOrderDetailApi;
  listOrderId: string[];
}
// Define the initial state using that type
const initialState: OrderState = {
  serviecs: '',
  imageList: [],
  order_id: '',
  dataOrder: {
    code: '',
    created_at: '',
    created_id: '',
    order_date: '',
    status_id: '',
    updated_at: '',
    updated_id: '',
    user_id: '',
  },
  dataOrderDetail: {
    id: '',
    order_id: '',
    check_date: '',
    check_hour: '',
    serviecs: '',
    serviecsDetail: '',
    serviecsDetailSelect: '',
    doctorSelect: '',
    special: 'hour',
    image: [],
    note: '',
    isFamily: false,
  },
  dataOrderDetailApi: {
    checkDay: '',
    checkHour: '',
    code: '',
    created_at: '',
    created_by: '',
    doctors: '',
    image: '',
    note: '',
    order_id: '',
    reciever_id: '',
    service: '',
    service_detail: '',
    service_detail_select: '',
    special: '',
    updated_at: '',
    updated_by: '',
    isFamily: false,
  },
  listOrderId: [],
};

export const orderSlice = createSlice({
  name: 'order',
  // `orderSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`

    loadImageList: (state, action) => {
      state.imageList = action.payload;
    },

    loadOrderId: (state, action) => {
      state.order_id = action.payload;
    },
    loadDataOrder: (state, action) => {
      state.dataOrder = action.payload;
    },
    loadDataOrderDetail: (state, action) => {
      state.dataOrderDetail = action.payload;
    },
    loadDataOrderDetailApi: (state, action) => {
      state.dataOrderDetailApi = action.payload;
    },
    addOrderId: (state, action: PayloadAction<string[]>) => {
      state.listOrderId = action.payload;
    },
  },
});

export const {
  loadDataOrder,
  loadDataOrderDetail,
  loadOrderId,
  loadImageList,
  loadDataOrderDetailApi,
  addOrderId,
} = orderSlice.actions;

export default orderSlice.reducer;

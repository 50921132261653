import React from 'react';
import { ToastContainer, ToastContainerProps, toast, ToastContent, TypeOptions, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Toaster {
  className?: string;
  children?: ToastContent;
}

export const Toaster: React.FC<Toaster> = ({ className: additionalClassName = '', children }) => {
  const componentClassName = 'a-toaster';
  const className = `${componentClassName} ${additionalClassName}`.trim();

  return <div className={className}>{children}</div>;
};

let toastId: React.ReactText | null = null;
export const NotifyToast = (label?: React.ReactNode, type?: TypeOptions, onOpen?: () => void) => {
  if (toastId && toast.isActive(toastId)) toast.dismiss(toastId);

  if (label) {
    toastId = toast(label, {
      delay: 200,
      type: type,
      onOpen: () => {
        onOpen && onOpen();
      },
    });
  } else {
    onOpen && onOpen();
  }
};

export const ActionToast: React.FC<ToastContainerProps> = props => (
  <ToastContainer
    {...props}
    transition={Slide}
    autoClose={4000}
    position="top-right"
    hideProgressBar
    closeButton={false}
    theme="colored"
    limit={5}
  />
);

import React from 'react';
import { ButtonContainer } from 'src/components/molecules/button-container';
import { ModalBox } from '../modal-box';
export interface ConfirmAlertProps {
  cancelButton: React.ReactNode;
  submitButton: React.ReactNode;
  children: React.ReactNode;
  title: string;
  handleCloseRequest: () => void;
}

export const ConfirmAlert: React.FC<ConfirmAlertProps> = props => (
  <div className="o-confirm-alert">
    <ModalBox title={props.title} isOpen handleCloseRequest={props.handleCloseRequest}>
      <div className="o-confirm-alert__main">{props.children}</div>
      <ButtonContainer>
        {props.cancelButton}
        {props.submitButton}
      </ButtonContainer>
    </ModalBox>
  </div>
);

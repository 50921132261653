import { createSlice } from '@reduxjs/toolkit';
import { STORAGE_KEYS } from 'src/constants/constants';
import { removeFromLocalStorage, saveToLocalStorage } from 'src/libs/utils';

export interface UserInfo {
  id: number;
  userName: string;
  address: string;
  dateOfBirth: string;
  email: string;
  gender: string;
  jobId: number;
  jobNote?: string;
  fullName: string;
  phone: string;
  urlImage: string;
  userId: number;
  infoId: number;
}

export interface UserEditInfo {
  id: number;
  userName: string;
  address: string;
  dateOfBirthday: string;
  email: string;
  gender: string;
  jobId: number;
  jobNote?: string;
  fullName: string;
  phone: string;
  urlImage: string;
}

export interface UserInfoEditResponse {
  data: UserEditInfo;
}

export interface AuthState {
  userInfo?: UserInfo;
}

// Define the initial state using that type
const initialState: AuthState = {
  userInfo: undefined,
};

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const loginInformation: UserInfo = action.payload;

      saveToLocalStorage(STORAGE_KEYS.USER_ID, loginInformation.id);
      state.userInfo = { ...loginInformation };
    },
    removeUserInfo: state => {
      removeFromLocalStorage(STORAGE_KEYS.USER_ID);
      state.userInfo = undefined;
    },
  },
});

export const { setUserInfo, removeUserInfo } = authSlice.actions;
export default authSlice.reducer;

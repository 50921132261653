import React, { CSSProperties } from 'react';
import { mapModifiers } from 'src/libs/component';

export const HEADING_LEVEL = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

export type headingSize = typeof HEADING_LEVEL[number];
export interface HeadingProps {
  children: React.ReactNode;
  level: headingSize;
  textColor?: 'color-blue' | 'color-red';
  style?: CSSProperties;
  alignCenter?: boolean;
  alignLeft?: boolean;
  bold?: boolean;
}

export const Heading: React.FC<HeadingProps> = props => {
  const TagName = props.level;

  return (
    <TagName
      style={props.style && props.style}
      className={mapModifiers(
        'a-heading',
        props.level,
        props.textColor,
        props.alignCenter && 'center',
        props.alignLeft && 'left',
        props.bold && 'bold'
      )}
    >
      {props.children}
    </TagName>
  );
};

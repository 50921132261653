import React from 'react';
import { TextLink } from '../text-link';

export interface ContactProps {
  children: React.ReactNode;
  name: string;
  href: string;
}

export const Contact: React.FC<ContactProps> = ({ children, name, href }) => (
  <div className="a-contact">
    <span>
      <img src={name} className="a-contact__icon" />
    </span>
    <TextLink href={href} useExternalLink>
      {children}
    </TextLink>
  </div>
);

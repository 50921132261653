import React, { useEffect } from 'react';

import { Footer } from 'src/components/organisms/footer';
import { HeaderTop } from 'src/components/organisms/header-top';

import { scrollToTop } from 'src/libs/utils';

export interface DetailPagesProps {
  navBar?: React.ReactNode;
  children: React.ReactNode;
}

export const DetailPages: React.FC<DetailPagesProps> = ({ navBar, children }) => {
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="t-detail-pages">
      <div className="t-detail-pages__header">
        <HeaderTop />
      </div>
      <div className="t-detail-pages__main">
        <div className="container">
          {navBar && <section className="t-detail-pages__main--navigation-bar">{navBar}</section>}
          <section className="t-detail-pages__main--form">{children}</section>
        </div>
      </div>
      <div className="t-home-page__footer">
        <Footer />
      </div>
    </div>
  );
};

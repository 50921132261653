import { createSlice } from '@reduxjs/toolkit';
interface FindHasFollow {
  group: {
    value: number | null;
    label: string;
  };
  follow: {
    value: number | null;
    label: string;
  };
}
interface FindNoFollow {
  phone: string;
  name: string;
}
interface PatientInformation {
  isFollower: boolean;
  findHasFollow: FindHasFollow;
  findNoFollow: FindNoFollow;
}
const initialState: PatientInformation = {
  isFollower: true,
  findHasFollow: {
    group: {
      value: null,
      label: '',
    },
    follow: {
      value: null,
      label: '',
    },
  },
  findNoFollow: {
    phone: '',
    name: '',
  },
};
export const findPatientSlice = createSlice({
  name: 'findPatientSlice',
  initialState,
  reducers: {
    setClear: state => {
      state.findHasFollow = initialState.findHasFollow;
      state.findNoFollow = initialState.findNoFollow;
      state.isFollower = initialState.isFollower;
    },
    saveFindPatientIsFollower: (state, action) => {
      state.isFollower = action.payload;
    },
    saveFindPatientFindNoFollower: (state, action) => {
      if (action.payload.type) {
        state.findNoFollow[action.payload.type === 'phone' ? 'phone' : 'name'] = action.payload.value;
      } else {
        state.findNoFollow = action.payload;
      }
    },
    saveFindPatientFindHasFollower: (state, action) => {
      if (action.payload.type === 'group') {
        state.findHasFollow.group = {
          label: action.payload.group.label,
          value: action.payload.group.value,
        };
      } else if (action.payload.type === 'follow') {
        state.findHasFollow.follow = {
          label: action.payload.follow.label,
          value: action.payload.follow.value,
        };
      }
    },
  },
});

export const {
  saveFindPatientIsFollower,
  saveFindPatientFindNoFollower,
  saveFindPatientFindHasFollower,
  setClear,
} = findPatientSlice.actions;
export default findPatientSlice.reducer;

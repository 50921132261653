import { CategoryItems, ICustomerBooking, ItemObject, OrderForm } from './../../../api/models/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDiagnosticTestBookingSuccess {
  atHome?: boolean;
  bookingUser: {
    doctor?: boolean;
    fullName: string;
    phone: string;
    userId: number;
  };
  category: {
    id: number;
    name: string;
    place?: string;
  };
  contrast?: boolean;
  render?: boolean;
  dateTest: string;
  dateTestExpired: string;
  orderId: number;
  note?: string;
  orderAddress: string;
  orderPhone: string;
  orderType: string;
  items: ItemObject[];
  infomation: {
    id: number;
    dateOfBirthday: string;
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    address: string;
  };
}

export interface IDiagTestFormEditValues {
  atHome: boolean;
  orderAddress: string;
  orderPhone: string;
  note: string;
  testDay: string;
  testHour: string;
  infomation?: ICustomerBooking;
  categoryId: number;
  packageTestCategories: CategoryItems[];
  requestTestCategories: CategoryItems[];
  clinicAddress: string;
}

export interface DepartmentInfo {
  checked?: boolean;
  name?: string;
}

export interface InfoUserProps {
  id: number;
  address: string;
  dateOfBirthday: string;
  email?: string;
  gender: string;
  jobId: number;
  jobNote?: string;
  active: boolean;
  fullName: string;
  phone: string;
  bankName?: string;
  accountNumber?: string;
  momo?: string;
  department1?: DepartmentInfo;
  department2?: DepartmentInfo;
  masters?: DepartmentInfo;
  doctor?: DepartmentInfo;
  associateProfessor?: DepartmentInfo;
  professor?: DepartmentInfo;
  hospital: string;
  urlImage: string;
  avatar?: File;
}

export interface DataPropsDiagnosticTest {
  isFamily?: boolean;
  inforBookingForSelf: OrderForm;
  inforBookingForOther: OrderForm;
}

// Define a type for the slice state
interface DiagnosticTestState {
  infoBookings: DataPropsDiagnosticTest;
  infoBookingSuccessList: IDiagnosticTestBookingSuccess[];
}

// Define the initial state using that type
const initialState: DiagnosticTestState = {
  infoBookings: {
    inforBookingForOther: {
      listOrder: [],
    },
    inforBookingForSelf: {
      listOrder: [],
    },
  },
  infoBookingSuccessList: [],
};

export const diagnosticTestSlice = createSlice({
  name: 'diagnostictest',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReset: state => {
      state.infoBookings = initialState.infoBookings;
      state.infoBookingSuccessList = initialState.infoBookingSuccessList;
    },
    setInfoBookings: (state, action: PayloadAction<DataPropsDiagnosticTest>) => {
      state.infoBookings = action.payload;
    },
    setInfoBookingSuccessList: (state, action) => {
      state.infoBookingSuccessList = action.payload;
    },
  },
});

export const { setInfoBookings, setInfoBookingSuccessList, setReset } = diagnosticTestSlice.actions;

export default diagnosticTestSlice.reducer;

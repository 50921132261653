import React, { useEffect } from 'react';
import { loadFromCookies, saveToCookies } from 'src/libs/utils';
import i18n from 'src/utils/translate/config';

export interface Theme {
  theme: 'light' | 'dark';
}

export const SelectLanguage: React.FC<Theme> = () => {
  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    saveToCookies({ name: 'language', value: language });
  };

  const currentLanguage = i18n.language;

  useEffect(() => {
    i18n.init().then(() => {
      i18n.changeLanguage(loadFromCookies('language') || 'vi');
    });
  }, []);

  useEffect(() => {
    renderDisplayFlag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const renderDisplayFlag = () => {
    switch (currentLanguage) {
      case 'vi':
        return <img src="/images/vietnam-flag-icon.svg" alt="Viet Nam FLag" />;
      case 'en':
        return <img src="/images/english-flag-icon.svg" alt="England FLag" />;
      case 'zh':
        return <img src="/images/china-flag-icon.svg" alt="Viet Nam FLag" />;
    }
  };

  return (
    <div className="select-language">
      <div className="select-language__display">{renderDisplayFlag()}</div>
      <div className="select-language__menu">
        <div className="">
          <div
            className="item"
            onClick={() => {
              handleChangeLanguage('vi');
            }}
          >
            <img src="/images/vietnam-flag-icon.svg" alt="Viet Nam FLag" />
            <span>Tiếng Việt</span>
          </div>
          <div
            className="item"
            onClick={() => {
              handleChangeLanguage('en');
            }}
          >
            <img src="/images/english-flag-icon.svg" alt="England FLag" />
            <span>English</span>
          </div>
          <div
            className="item"
            onClick={() => {
              handleChangeLanguage('zh');
            }}
          >
            <img src="/images/china-flag-icon.svg" alt="Viet Nam FLag" />
            <span>中文</span>
          </div>
        </div>
      </div>
    </div>
  );
};

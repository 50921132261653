import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderFormRequestObject } from 'src/api/models/common';
import { IDiagnosticTestBookingSuccess } from '../diagnostic-test-slice';

export interface IDiagnosticTestModal {
  listOrder: OrderFormRequestObject;
  infoBookingSuccessList?: IDiagnosticTestBookingSuccess[];
}

const initialState: IDiagnosticTestModal = {
  listOrder: {
    atHome: false,
    categoryTypeId: 0,
    orderAdress: '',
    orderPhone: '',
    orderType: '2',
    packageTestItems: [],
    requestTestItems: [],
    testDay: '',
    testTime: '',
    clinicAddress: '',
  },
  infoBookingSuccessList: undefined,
};

export const diagnosticTestModalSlice = createSlice({
  name: 'diagnosticTestModal',
  initialState,
  reducers: {
    setListOrder: (state, action: PayloadAction<OrderFormRequestObject>) => {
      state.listOrder = action.payload;
    },
    setInfoBookingSuccessList: (state, action) => {
      state.infoBookingSuccessList = action.payload;
    },
  },
});

export const { setListOrder, setInfoBookingSuccessList } = diagnosticTestModalSlice.actions;

export default diagnosticTestModalSlice.reducer;

import {
  UserAvatarRequestObj,
  UserAvatarResponseObj,
  UserByPhoneResponse,
  UserInfoResponse,
  UserLoginRequest,
  UserLoginResponse,
  UserPutPasswordResponse,
  UserPutRequest,
  UserPutResetPasswordResponse,
  UserRegistrationRequest,
  UserRelativeResponse,
} from './models/user';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AsyncReturnType, HttpResponseObject } from './models/common';
import { useMutation, UseMutationOptions, UseQueryOptions, useQuery } from 'react-query';
import { UserInfoEditResponse } from 'src/redux/features/login-slice';

export const postUser = <TData = AxiosResponse<UserLoginResponse>>(
  param: UserLoginRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/login`, param, options);
};

export const usePostUser = <
  TData = AsyncReturnType<typeof postUser, AxiosResponse<UserLoginResponse>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UserLoginRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { data: UserLoginRequest }, TContext>(props => {
    const { data } = props || {};

    return postUser<TData>(data, axiosOptions);
  }, mutationOptions);
};

export const getUserPhone = <TData = AxiosResponse<UserByPhoneResponse>>(
  phone: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/user-phone/${phone}`, options);
};

export const useGetUserPhone = <
  TData = AsyncReturnType<typeof getUserPhone, AxiosResponse<UserByPhoneResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: string }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { data: string }, TContext>(props => {
    const { data } = props || {};

    return getUserPhone<TData>(data, axiosOptions);
  }, mutationOptions);
};

// /user/{id}
export const getUser = <TData = AxiosResponse<UserInfoResponse>>(
  params: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/user/${params}`, options);
};

export const getUserQueryKey = (params: number) => [`/user/${params}`];

export const useGetUser = <
  TQueryFnData = AsyncReturnType<typeof getUser, AxiosResponse<UserInfoResponse>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  params: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getUserQueryKey(params);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getUser<TQueryFnData>(params, axiosOptions),
    { ...queryOptions, cacheTime: 0 }
  );

  return { queryKey, ...query };
};

// sign-up
export const postSignUpUser = <TData = AxiosResponse<UserRegistrationRequest>>(
  param: UserRegistrationRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/signup`, param, options);
};

export const usePostSignUpUser = <
  TData = AsyncReturnType<typeof postSignUpUser, AxiosResponse<UserRegistrationRequest>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UserRegistrationRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { data: UserRegistrationRequest }, TContext>(props => {
    const { data } = props || {};

    return postSignUpUser<TData>(data, axiosOptions);
  }, mutationOptions);
};

// /user/relative/{id}
export const getUserRelative = <TData = AxiosResponse<UserRelativeResponse>>(
  params: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/relative/${params}`, options);
};

export const getUserRelativeQueryKey = (params: number) => [`/relative/${params}`];

export const useGetUserRelative = <
  TQueryFnData = AsyncReturnType<typeof getUserRelative, AxiosResponse<UserRelativeResponse>>,
  TError = HttpResponseObject,
  TData = TQueryFnData
>(
  params: number,
  options?: {
    query?: UseQueryOptions<TQueryFnData, TError, TData>;
    axios?: AxiosRequestConfig;
  }
) => {
  const { query: queryOptions, axios: axiosOptions } = options || {};
  const queryKey = queryOptions?.queryKey ?? getUserRelativeQueryKey(params);
  const query = useQuery<TQueryFnData, TError, TData>(
    queryKey,
    () => getUserRelative<TQueryFnData>(params, axiosOptions),
    queryOptions
  );

  return { queryKey, ...query };
};

// put up
export const putUser = <TData = AxiosResponse<UserInfoEditResponse>>(
  id: number,
  param: UserPutRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.put(`/user/${id}`, param, options);
};

export const usePutUser = <
  TData = AsyncReturnType<typeof putUser, AxiosResponse<UserInfoEditResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; data: UserPutRequest }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; data: UserPutRequest }, TContext>(props => {
    const { id, data } = props || {};

    return putUser<TData>(id, data, axiosOptions);
  }, mutationOptions);
};

// put password id
export const putPasswordUser = <TData = AxiosResponse<UserPutPasswordResponse>>(
  id: number,
  param: UserPutPasswordResponse,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.put(`/user-password/${id}`, param, options);
};

export const usePutPasswordUser = <
  TData = AsyncReturnType<typeof putPasswordUser, AxiosResponse<UserPutPasswordResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number; data: UserPutPasswordResponse }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { id: number; data: UserPutPasswordResponse }, TContext>(props => {
    const { id, data } = props || {};

    return putPasswordUser<TData>(id, data, axiosOptions);
  }, mutationOptions);
};

export const putResetPasswordUser = <TData = AxiosResponse<UserPutResetPasswordResponse>>(
  param: UserPutResetPasswordResponse,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.put(`/user-reset-password`, param, options);
};

export const usePutResetPasswordUser = <
  TData = AsyncReturnType<typeof putResetPasswordUser, AxiosResponse<UserPutResetPasswordResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: UserPutResetPasswordResponse }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { data: UserPutResetPasswordResponse }, TContext>(props => {
    const { data } = props || {};

    return putResetPasswordUser<TData>(data, axiosOptions);
  }, mutationOptions);
};

//get infoUser success login
export const getInfoUserSuccessLogin = <TData = AxiosResponse<UserInfoResponse>>(
  params: number,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/user/${params}`, options);
};

export const getInfoUserSuccessLoginQueryKey = (params: number) => [`/user/${params}`];
export const useGetInfoUserSuccessLogin = <
  TData = AsyncReturnType<typeof getInfoUserSuccessLogin, AxiosResponse<UserInfoResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { params: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { params: number }, TContext>(props => {
    const { params } = props || {};

    return getInfoUserSuccessLogin<TData>(params, axiosOptions);
  }, mutationOptions);
};

export const postLogOut = <TData = AxiosResponse<HttpResponseObject>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/logout-page`, options);
};

export const usePostLogOut = <
  TData = AsyncReturnType<typeof postLogOut, AxiosResponse<HttpResponseObject>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, void, TContext>(() => {
    return postLogOut<TData>(axiosOptions);
  }, mutationOptions);
};

export const postUpdateUserAvatar = <TData = AxiosResponse<UserAvatarResponseObj>>(
  param: UserAvatarRequestObj,
  options?: AxiosRequestConfig
): Promise<TData> => {
  const formData = new FormData();

  formData.append('file', param.avtarImg);

  return axios.post(`/user-avatar/${param.userId}`, formData, options);
};

export const usePostUpdateUserAvatar = <
  TData = AsyncReturnType<typeof postUpdateUserAvatar, AxiosResponse<UserAvatarResponseObj>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { param: UserAvatarRequestObj }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { param: UserAvatarRequestObj }, TContext>(props => {
    const { param } = props || {};

    return postUpdateUserAvatar<TData>(param, axiosOptions);
  }, mutationOptions);
};

export const postRemoveUserAvatar = <TData = AxiosResponse<HttpResponseObject>>(
  userId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.put(`/user-delete-avatar/${userId}`, options);

export const usePostRemoveUserAvatar = <
  TData = AsyncReturnType<typeof postRemoveUserAvatar, AxiosResponse<HttpResponseObject>>,
  TError = HttpResponseObject,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number }, TContext>(props => {
    const { userId } = props || {};

    return postRemoveUserAvatar<TData>(userId, axiosOptions);
  }, mutationOptions);
};

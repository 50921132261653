import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateResource from '../../constants/translate.json';

i18n.use(initReactI18next).init({
  lng: 'vi',
  // language resources
  resources: translateResource,
});

export default i18n;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapModifiers } from 'src/libs/component';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  label?: string;
  placeholder?: string;
  modifiers?: 'password' | 'number' | 'file' | 'text';
  noMarginTop?: boolean;
  required?: boolean;
  errorMessage?: string;
  horizontal?: boolean
  isError?: boolean;
  password?: boolean;
  ref?: React.Ref<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = React.forwardRef(
  (
    { label, placeholder, modifiers, required, errorMessage, isError, password, horizontal, noMarginTop, ...inputProps },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const inputType = modifiers === 'password' && isShowPassword ? 'text' : modifiers;
    const { t } = useTranslation();

    return (
      <div
        className={mapModifiers(
          'a-input',
          (errorMessage || isError) && 'error',
          noMarginTop && 'no-margin-top',
          horizontal && 'horizontal'
        )}
      >
        {horizontal && <label className='a-input__label-horizontal'>{label}</label>}
        <input
          ref={ref}
          {...inputProps}
          autoComplete="off"
          className={mapModifiers('a-input__effect', modifiers)}
          type={inputType}
          placeholder={placeholder}
        />
        {password && (
          <button
            type="button"
            tabIndex={-1}
            onClick={() => setIsShowPassword(!isShowPassword)}
            className={`a-input__password ${isShowPassword ? 'a-input__password--show' : 'a-input__password--hide'
              }`}
          />
        )}
        {!horizontal && <div className="a-input__label">
          <label>{label}</label>
          {required ? <span className="a-input__label--required">{t('common.mandatory')}</span> : null}
        </div>}
        {errorMessage && <p className="a-input__label--error">{t(errorMessage)}</p>}
      </div>
    );
  }
);

Input.displayName = 'input';

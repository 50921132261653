import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImageAnalysationBookingSuccess, IImageAnalysationFormValues } from '../image-analysation-slice';

export interface ImageAnalysationModalState {
  infoBooking?: IImageAnalysationFormValues;
  infoImageBookingSuccessList?: IImageAnalysationBookingSuccess[];
}

const initialState: ImageAnalysationModalState = {
  infoBooking: undefined,
  infoImageBookingSuccessList: [],
};

export const imageAnalysationModalSlice = createSlice({
  name: 'imageAnalysationModal',
  initialState,
  reducers: {
    setInfoBookings: (state, action: PayloadAction<IImageAnalysationFormValues>) => {
      state.infoBooking = action.payload;
    },
    setInfoImageBookingSuccessList: (state, action) => {
      state.infoImageBookingSuccessList = action.payload;
    },
  },
});

export const { setInfoBookings, setInfoImageBookingSuccessList } = imageAnalysationModalSlice.actions;

export default imageAnalysationModalSlice.reducer;

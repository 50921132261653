import React from 'react';
import { mapModifiers } from 'src/libs/component';

export interface ImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'className'> {
  modifiers?: 'logo';
  url: string;
}

export const Image: React.FC<ImageProps> = ({ url, modifiers, ...imgProps }) => (
  <img {...imgProps} src={url} className={mapModifiers('a-image', modifiers)} />
);

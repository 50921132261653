/* eslint-disable react/jsx-key */

import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { HttpInterceptor } from './libs/http-interceptor';

import { persistor, store } from './redux/store';

import './styles/index.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { Loading } from './components/atoms/loading';
import * as smoothscroll from 'smoothscroll-polyfill';
import { ActionToast } from './components/atoms/action-toast';
import 'viewport-extra';

smoothscroll.polyfill();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ActionToast />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <HttpInterceptor>
            <PersistGate loading={<Loading open />} persistor={persistor}>
              <App />
            </PersistGate>
          </HttpInterceptor>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

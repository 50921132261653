import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Avatar, { Cache, ConfigProvider } from 'react-avatar';
import { mapModifiers } from 'src/libs/component';
import { BASE_URL } from 'src/libs/http-interceptor';

interface MenuContextProps {
  title: string;
  avatarImg?: string;
}

const cache = new Cache({
  // Keep cached source failures for up to 7 days
  sourceTTL: 7 * 24 * 3600 * 1000,

  // Keep a maximum of 20 entries in the source cache
  sourceSize: 20,
});

export const MenuContext: React.FC<MenuContextProps> = ({ title, avatarImg, children }): JSX.Element => {
  const ref = useRef<HTMLSpanElement>(null);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const menuRef = ref.current;

    const hanleClick = (event: MouseEvent) => {
      if (event.target) {
        if (event.target !== menuRef) setIsShow(false);
        else if (event.target === menuRef) setIsShow(true);
      }
    };

    document.addEventListener('click', hanleClick);

    return () => document.removeEventListener('click', hanleClick);
  }, []);

  return (
    <div className={mapModifiers('a-menu-context', isShow && 'active')}>
      <div>
        <span className="a-menu-context__title" ref={ref}>
          {title}
          <span>
            <ConfigProvider cache={cache}>
              <Avatar
                className="a-menu-context__avatar"
                size="38"
                round
                value={avatarImg ? `${BASE_URL}${avatarImg}` : ''}
                maxInitials={1}
                src={avatarImg ? `${BASE_URL}${avatarImg}` : ''}
                name={title}
              />
            </ConfigProvider>
          </span>
        </span>
      </div>
      <ul className="a-menu-context__items">{children}</ul>
    </div>
  );
};

interface MenuContextItemProps {
  href: string;
}

export const MenuContextItem: React.FC<MenuContextItemProps> = ({ href, children }) => (
  <li className="a-menu-context__item">
    <Link to={href}>{children}</Link>
  </li>
);

export const STORAGE_PREFIX = 'RHC_';
export const STORAGE_KEYS = {
  IS_AUTH: 'isAuth',
  USER_NAME: 'userName',
  USER_ID: 'userId',
  USER_TOKEN: 'access_token',
} as const;

export const REGEX_NAME =
  /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/;
export const REGEX_PHONE_NUMBER = /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/;
export const REGEX_BLOOD_PRESSURE = /^\d{1,3}\/\d{1,3}(\/\d{1,3})*$/;
export const REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const REGEX_NUMBER = /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/;
export const REGEX_ONLY_NUMBER = /^[1-9]\d*(\.\d+)?$/;
export const REGEX_ONLY_NUMBER_INCLUDE_0 = /^[0-9]\d*(\.\d+)?$/;

export const IMAGE_OPTIONS = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
};

export const ORDER_TYPES = {
  0: 'SELF_ORDER',
  1: 'RELATIVE_ORDER',
  2: 'DOCTOR_ORDER',
};

export const STATUS = {
  0: 'STATUS_BOOKED',
  1: 'STATUS_DONE',
  2: 'STATUS_CANCEL',
  3: 'STATUS_VERIFIED',
  4: 'STATUS_INFOSTORE',
};

export const DOCTOR_ROLE_ID = 1;
export const SPECIAL_RELATIVE_GROUP_ID = 1;
export const MAX_SIZE_FILE = 10485760; // Note: 10MB
export const PAGE_SIZE = 10;
export const STATUS_REJECTED_ALLOW_WATCH = 0;
export const STATUS_WAITING_ALLOW_WATCH = 1;
export const STATUS_ACCEPTED_ALLOW_WATCH = 2;
export const STATUS_UPDATE_REQUEST_ALLOW_WATCH = 3;

export const BOOKING_TYPES = {
  health: 'Khám sức khỏe',
  diag: 'Xét nghiệm chẩn đoán',
  image: 'Hình ảnh chẩn đoán',
  infostore: 'Lưu trữ dữ liệu',
};

export const EXPIRED_MODIFY_ORDER_AFTER_COMPLETE = 1 * 60 * 24; //24h
export const EXPIRED_MODIFY_ORDER_AFTER_COMPLETE_DEBUG = 2; //2p

export const OTHER_DIAGNOSTIC_PACKAGE_INDEX_PRODUCTION = 1003;
export const OTHER_DIAGNOSTIC_PACKAGE_INDEX = 1007;

export const CONTACT_PHONE = '0877365366';

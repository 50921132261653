import { createSlice } from '@reduxjs/toolkit';
import { CategoryIdRequest } from 'src/api/models/category';
import { ICustomerBooking, ItemObject } from 'src/api/models/common';

export interface IImageAnalysationFormValues {
  orderType: string;
  note?: string;
  testDay: string;
  testHour: string;
  infomation?: ICustomerBooking;
  isContrastAgents?: boolean;
  checkCategory?: boolean;
  categoryId: number;
  categories: CategoryIdRequest[];
  place?: string;
  previousPhoneNumber?: string;
  orderAddress: string;
  contactPhone: string;
}

export interface IImageAnalysationFormEditValues {
  orderType: string;
  note?: string;
  testDay: string;
  testHour: string;
  infomation?: ICustomerBooking;
  isContrastAgents?: boolean;
  categoryId: number;
  categories: CategoryIdRequest[];
}

export interface IImageAnalysationBookingSuccess {
  bookingUser: {
    doctor?: boolean;
    fullName: string;
    phone: string;
    userId: number;
  };
  dateTest: string;
  dateTestExpired: string;
  orderId: number;
  note?: string;
  category: {
    id: number;
    name: string;
    place?: string;
  };
  contrast?: boolean;
  orderType: string;
  items: ItemObject[];
  infomation: {
    id: number;
    dateOfBirthday: string;
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    address: string;
  };
  type: string;
  orderAddress: string;
}

export interface DataPropsImageAnalysation {
  isFamily?: boolean;
  inforBookingForSelf: IImageAnalysationFormValues[];
  inforBookingForOther: IImageAnalysationFormValues[];
}

// Define a type for the slice state
export interface ImageAnalysationState {
  infoBooking: DataPropsImageAnalysation;
  categoryList?: CategoryIdRequest[];
  infoBookingsuccessList?: IImageAnalysationBookingSuccess[];
}

// Define the initial state using that type
const initialState: ImageAnalysationState = {
  infoBooking: {
    inforBookingForOther: [],
    inforBookingForSelf: [],
  },
  categoryList: [],
  infoBookingsuccessList: [],
};

export const imageAnalysationSlice = createSlice({
  name: 'imageanalysation',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReset: state => {
      state.infoBooking = initialState.infoBooking;
      state.categoryList = initialState.categoryList;
      state.infoBookingsuccessList = initialState.infoBookingsuccessList;
    },
    setInfoBookings: (state, action) => {
      state.infoBooking = action.payload;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setInfoBookinguccessList: (state, action) => {
      state.infoBookingsuccessList = action.payload;
    },
  },
});

export const { setInfoBookings, setCategoryList, setInfoBookinguccessList, setReset } =
  imageAnalysationSlice.actions;

export default imageAnalysationSlice.reducer;

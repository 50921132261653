import { createSlice } from '@reduxjs/toolkit';

interface IPatientInfo {
  id: number;
  fullName: string;
  address: string;
  dateOfBirthday: string;
  email: string;
  gender: string;
  phone: string;
}

interface IPatientScheduleParamObject {
  month: number;
  year: number;
  patientId: number;
  doctorId: number;
  categoryTypeId: number;
}

interface IPatientInfoObject {
  info?: IPatientInfo;
  param?: IPatientScheduleParamObject;
}

const initialState: IPatientInfoObject = {
  info: undefined,
  param: undefined,
};

export const patientSlice = createSlice({
  name: 'patientSlice',
  initialState,
  reducers: {
    savePatientInfo: (state, action) => {
      state.info = { ...action.payload };
    },
    savePatientParam: (state, action) => {
      state.param = { ...action.payload };
    },
  },
});

export const { savePatientInfo, savePatientParam } = patientSlice.actions;
export default patientSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiseaseInfo, ExamInfoWithPrescriptionResponseObject } from 'src/api/models/prescription';

interface HealthCareEditDoctorEditState {
  examInfo?: ExamInfoWithPrescriptionResponseObject;
  diseaseInfo?: DiseaseInfo;
}

const initialState: HealthCareEditDoctorEditState = {
  diseaseInfo: undefined,
  examInfo: undefined,
};



export const healthCareDoctorEditSlice = createSlice({
  name: 'healthcare',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setExamInfo: (state, action: PayloadAction<ExamInfoWithPrescriptionResponseObject>) => {
      state.examInfo = action.payload;
    },
    setDiseaseInfo: (state, action: PayloadAction<DiseaseInfo>) => {
      state.diseaseInfo = action.payload;
    },
  },
});

export const { setExamInfo, setDiseaseInfo } = healthCareDoctorEditSlice.actions;

export default healthCareDoctorEditSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

interface UserInfoState {
  user_id: string;
  infoUser: {
    phone: string;
    id: string;
  };
  infoCustomerUser: {
    name: string;
    phone: string;
  };
}

// Define the initial state using that type
const initialState: UserInfoState = {
  user_id: '',
  infoUser: { phone: '', id: '' },
  infoCustomerUser: {
    name: '',
    phone: '',
  },
};

export const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    loadUserId: (state, action) => {
      state.user_id = action.payload;
    },
    loadInfoUser: (state, action) => {
      state.infoUser = action.payload;
    },
    loadInfoCustomerUser: (state, action) => {
      state.infoCustomerUser = action.payload;
    },
  },
});

export const { loadUserId, loadInfoUser, loadInfoCustomerUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;

import React from 'react';
import { mapModifiers } from 'src/libs/component';
import { useTranslation } from 'react-i18next';
export interface TextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'className' | 'onChange'> {
  title: string;
  required?: boolean;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

}

export const Textarea: React.FC<TextareaProps> = ({
  title,
  onChange,
  required,
  errorMessage,
  ...textareaProps

}) => {
  const { t } = useTranslation();
  return (
    <div className={mapModifiers('a-textarea', errorMessage && 'error')}>
      <div className="a-textarea__label">
        <label className="a-textarea__title">{title}</label>
        {required ? <span className="a-textarea__tag--required">{t('common.mandatory')}</span> : null}
      </div>
      <textarea {...textareaProps} className="a-textarea__input" onChange={onChange} spellCheck={false} />
      {errorMessage && <p className="a-textarea__tag--error">{t(errorMessage)}</p>}
    </div>
  );
};

import React from 'react';
import { BreadScrumItem, BreadScrum } from 'src/components/atoms/breadscrum';
import { DetailPages } from 'src/components/templates/detail-pages';

import PATHES from 'src/constants/pathes';
import { useTranslation } from 'react-i18next';
import { setReset } from 'src/redux/features/health-care-slice';
import { useAppDispatch } from 'src/redux/hooks';
import { HealthRecordForm } from 'src/components/organisms/health-record-form';

export const DetailHealthRecord: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const ResetDataRedux = () => {
    dispatch(setReset());
  };

  return (
    <DetailPages
      navBar={
        <BreadScrum>
          <BreadScrumItem href={PATHES.HOME_PAGE} onClick={ResetDataRedux}>
            {t('signup.homepage')}
          </BreadScrumItem>
          <BreadScrumItem>{t('record.record')}</BreadScrumItem>
        </BreadScrum>
      }
    >
      <HealthRecordForm />
    </DetailPages>
  );
};

export default DetailHealthRecord;

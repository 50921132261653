import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { AsyncReturnType } from './models/common';
import { GetDashBoardResponse } from './models/dashboard';

const getDashBoard = <TData = AxiosResponse<GetDashBoardResponse>>(
  userId: number,
  options?: AxiosRequestConfig
): Promise<TData> => axios.get(`/dashboard/${userId}`, options);

export const useGetDashBoard = <
  TData = AsyncReturnType<typeof getDashBoard, AxiosResponse<GetDashBoardResponse>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { userId: number }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  return useMutation<TData, TError, { userId: number }, TContext>(props => {
    const { userId } = props || {};

    return getDashBoard<TData>(userId, axiosOptions);
  }, mutationOptions);
};
